import { APPBAR_HEIGHT, IconButton as UiIconButton, styled, styledForMobile } from "@l2r-front/l2r-ui"

import { ADDITIONAL_SIDEBAR_WIDTH } from "../../../../common/constants/layout"

import { HorizontalSignFilters as L2rHorizontalSignFilters } from "../HorizontalSignFilters"
import { HorizontalSignTypeList as L2RHorizontalSignTypeList } from "../HorizontalSignTypeList"

export const SIDEBAR_SPACING = 8
export const SIDEBAR_SPACING_MOBILE = 4

export const Container = styled("div")(() => ({
    height: "100%",
    overflowY: "auto",
}))

export const HorizontalSignTypeList = styled(L2RHorizontalSignTypeList)(({ theme }) => ({
    padding: theme.spacing(8),
}))

export const AdditionalSidebar = styled("div")(({ index }) => ({
    height: `calc(100% - ${APPBAR_HEIGHT}px)`,
    position: "fixed",
    left: `calc(500px + ${index * ADDITIONAL_SIDEBAR_WIDTH}px)`,
    top: APPBAR_HEIGHT,
    width: ADDITIONAL_SIDEBAR_WIDTH,
    overflowY: "auto",
}))

export const TitleSideComponentWrapper = styled("div")({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
})

export const IconButton = styled(UiIconButton)(() => ({
    marginLeft: "auto",
}))

export const HorizontalSignFilters = styledForMobile(L2rHorizontalSignFilters)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    boxShadow: theme.shadows[27],
    left: 0,
    position: "absolute",
    right: 0,
    zIndex: theme.zIndex.appBar + 1,
}))

export const TagsFilterListWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 8, 2, 8),
}))