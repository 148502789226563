import { useCallback, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworksContext } from "@l2r-front/l2r-networks"
import { Select, useTheme, useMediaQuery } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./NetworkSelector.styled"

export function NetworkSelector(props) {
    const [networksState, networksDispatch] = useNetworksContext()
    const { networks, selectedNetwork } = networksState
    const { swapToNetwork } = networksDispatch
    const theme = useTheme()
    const { t } = useTranslation(I18N_NAMESPACE)
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

    const changeSelectedNetworkSlug = useCallback((event, newNetwork) => {
        if (newNetwork && newNetwork.slug !== selectedNetwork.slug) {
            swapToNetwork(newNetwork.slug)
        }
    }, [selectedNetwork, swapToNetwork])

    const networksList = useMemo(() => {
        if (!networks) {
            return null
        }

        return networks.map(network => (
            {
                label: network.name,
                slug: network.slug,
            }
        ))
    }, [networks])

    if (networksList?.length) {
        return (
            <Styled.Autocomplete
                large={isLargeScreen}
                disableClearable
                selectOnFocus={false}
                value={{ label: selectedNetwork.name, slug: selectedNetwork.slug }}
                onChange={changeSelectedNetworkSlug}
                options={networksList}
                renderInput={(params) =>
                    <Styled.Input
                        {...params}
                    />
                }
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.slug === value.slug}
                slotProps={{
                    paper: {
                        elevation: 3,
                        sx: {
                            borderRadius: theme.spacing(3),
                            marginTop: theme.spacing(1),
                        },
                    },
                }}
                noOptionsText={t(I18N_NAMESPACE, "containers.networkSelector.noOptions")}
                {...props}
            />
        )
    }

    return null
}

NetworkSelector.propTypes = Select.propTypes
