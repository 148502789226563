import { useMemo } from "react"

import { GEOJSONLINE, GEOJSONPOINT } from "../../constants/geometryIds"
import { useRoadwayDispatchContext, useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useDamagesStats } from "../../hooks/queries/damages/useDamagesStats"
import { useNetworkConditionForYearlyStatus } from "../../hooks/queries/networkConditions/useNetworkConditionForYearlyStatus"

import { DamagesList } from "../DamagesList"

export function NetworkDamagesList() {
    const {
        availableNotationType,
        selectedNetworkYearlyStatus,
    } = useRoadwayStateContext()

    const {
        isLoading: isLoadingNetworkCondition,
        isError: isErrorNetworkCondition,
        networkCondition,
    } = useNetworkConditionForYearlyStatus(selectedNetworkYearlyStatus)

    const { getSurveyForNetworkGrading } = useRoadwayDispatchContext()

    const damagesTypes = networkCondition?.damageTypes
    const notationSurvey = getSurveyForNetworkGrading(selectedNetworkYearlyStatus)

    const {
        data: damagesStats,
        isLoading: isLoadingDamagesStat,
        isError: isErrorDamagesStat,
    } = useDamagesStats(
        { network_condition: networkCondition?.uuid },
        { enabled: !!networkCondition?.uuid },
    )

    const currentNetworkDamagesStats = useMemo(() => {
        if (!!damagesStats && !isErrorDamagesStat && !isLoadingDamagesStat) {
            return damagesTypes.map((damageType) => {
                return getDamageStat(damageType, damagesStats)
            })
        }
        return null
    }, [damagesTypes, damagesStats, isLoadingDamagesStat, isErrorDamagesStat])

    const noDamageTextKey = useMemo(() => {
        if (!notationSurvey) {
            return "containers.roadwaySidebar.damages.noNotationDescription"
        }
        return "containers.roadwaySidebar.damages.noDamageForAudit"
    }, [availableNotationType])

    return <DamagesList
        damagesToDisplay={currentNetworkDamagesStats}
        isLoading={isLoadingNetworkCondition || (networkCondition && isLoadingDamagesStat)}
        isError={isErrorNetworkCondition || isErrorDamagesStat}
        noDamageTextKey={noDamageTextKey} />
}

function getDamageStat(damageType, damagesStats) {

    const isPonctualStat = damageType.geometryTypeid === GEOJSONPOINT
    const isLinearStat = damageType.geometryTypeid === GEOJSONLINE
    const damageCode = damageType.code

    if (isPonctualStat) {
        const damageStat = Object.values(damagesStats.count[damageCode] || {}).reduce((acc, statCount) => acc + statCount, 0)
        return ({ damageCode, damageStat })
    } else if (isLinearStat) {
        const damageStat = Object.values(damagesStats.length[damageCode] || {}).reduce((acc, statCount) => acc + statCount, 0)
        return ({ damageCode, damageStat })
    }
    return null
}
