import {
    APPBAR_HEIGHT,
    APPBAR_WITHOUT_MODULES_HEIGHT,
    Toolbar as UiToolbar,
    AppBar as UiAppBar,
    styled,
} from "@l2r-front/l2r-ui"

export const Toolbar = styled(UiToolbar)(({ theme }) => ({
    justifyContent: "space-between",
    gap: theme.spacing(8),
    minHeight: "0px !important",
    padding: theme.spacing(3, 8),
    height: APPBAR_WITHOUT_MODULES_HEIGHT,
}))

export const AppBar = styled(UiAppBar)(({ moduleTabs, theme }) => ({
    background: theme.palette["components/app-bar/button/default/main"].main,
    height: moduleTabs ? APPBAR_HEIGHT : APPBAR_WITHOUT_MODULES_HEIGHT,
}))