import { Link as L2RLink } from "react-router-dom"

import {
    APPBAR_WITHOUT_MODULES_HEIGHT,
    styled,
    styledForMobile,
    Typography as UiTypography,
} from "@l2r-front/l2r-ui"

import { AppBar as AppBarComponent } from "../../components/AppBar"
import { NetworkSelector as L2rNetworkSelector } from "../../containers/NetworkSelector"

export const TextContainer = styledForMobile("div")(({ isMobile, theme }) => ({
    alignItems: "baseline",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(7),
    height: `calc(100% - ${APPBAR_WITHOUT_MODULES_HEIGHT}px)`,
    justifyContent: isMobile ? "flex-start" : "center",
    right: isMobile ? 0 : "20%",
    padding: isMobile ? theme.spacing(16, 4) : 0,
    position: isMobile ? "block" : "absolute",
}))

export const Typography = styled(UiTypography)(({ theme }) => ({
    color: theme.palette["primary"].contrast,
}))

export const ImageContainer = styled("div")(({ src }) => ({
    backgroundImage: `url(${src})`,
    paddingTop: APPBAR_WITHOUT_MODULES_HEIGHT,
    height: "100%",
    width: "100%",
}))

export const PageContainer = styled("div")(({ theme }) => ({
    height: "100%",
    backgroundColor: theme.errorBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
}))

export const AppBar = styled(AppBarComponent)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 2,
}))

export const Link = styled(L2RLink)({
    alignItems: "center",
    display: "flex",
    height: "100%",
})

export const NetworkSelector = styled(L2rNetworkSelector)({
    marginLeft: "auto",
})

export const Logo = styled("img")(() => ({
    height: 36,
}))