import Popover from "@mui/material/Popover"
import { styled } from "@mui/material/styles"

export const L2RPopover = styled(Popover)(({ theme }) => ({
    pointerEvents: "none",
    marginTop: theme.spacing(1),

    "& .MuiPaper-root": {
        backgroundColor: theme.palette["surfaces/surface-primary"].main,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(3),
    },
}))