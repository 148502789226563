import React, { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useRoadwayDispatchContext, useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useDamagesStatsOnSegments } from "../../hooks/queries/damages/useDamagesStatsOnSegments"

import { DamagesList } from "../DamagesList"

export function RoadDamagesList() {

    const { road } = useParams()
    const { availableNotationType, selectedNetworkAssesmentType } = useRoadwayStateContext()
    const {
        data: damagesStatsOnRoad,
        isLoading,
        isError,
    } = useDamagesStatsOnSegments(road)

    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()
    const { getSurveyForNetworkGrading } = useRoadwayDispatchContext()

    const notationSurvey = getSurveyForNetworkGrading(selectedNetworkYearlyStatus)

    const fullDamagesStatsOnRoad = useMemo(() => {
        const roadDamages = (damagesStatsOnRoad || [])

        if (selectedNetworkAssesmentType) {
            const formattedSelectedNetworkAssesmentType = selectedNetworkAssesmentType.split("-")[1]
            const isSelectedNetworkAssesmentTypePresent = roadDamages.find(damage => damage.damageCode === formattedSelectedNetworkAssesmentType)

            if (!isSelectedNetworkAssesmentTypePresent) {
                return roadDamages.concat({
                    damageCode: formattedSelectedNetworkAssesmentType,
                    damageStat: 0,
                })
            }
        }

        return damagesStatsOnRoad
    }, [damagesStatsOnRoad, selectedNetworkAssesmentType])

    const noDamageTextKey = useMemo(() => {
        if (!notationSurvey) {
            return "containers.roadwaySidebar.damages.noNotationDescription"
        }
        return "containers.roadwaySidebar.damages.noDamageForSegment"
    }, [availableNotationType])

    return <DamagesList
        damagesToDisplay={fullDamagesStatsOnRoad}
        isLoading={isLoading}
        isError={isError}
        noDamageTextKey={noDamageTextKey} />
}
