import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { useAuthenticationStateContext } from "@l2r-front/l2r-auth"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useHandleBack } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { l2rApps } from "../../constants/l2rApps"
import { AvatarWithAccountMenu } from "../../containers/AvatarWithAccountMenu"

import { MobileReturnButton } from "../MobileReturnButton"

import * as Styled from "./MobileAppbar.styled"

export const MobileAppBar = () => {
    const { isAuthenticated } = useAuthenticationStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const matchAccountSettings = useMatch("/account_settings/*")
    const matchAppModules = useMatch("/:match1/:match2/:match3/*")

    const selectedApp = useMemo(() => {
        return matchAppModules?.params?.match2 ?? "l2rServices"
    }, [matchAppModules])

    const selectedModule = useMemo(() => {
        if (matchAccountSettings) {
            return "accountSettings"
        } else if (matchAppModules?.params?.match3) {
            return matchAppModules?.params?.match3
        } else {
            return "dashboard"
        }
    }, [matchAppModules, matchAccountSettings])

    const module = useMemo(() => {
        return l2rApps[selectedApp].modules[selectedModule]
    }, [selectedApp, selectedModule])

    const Icon = module?.icon

    const getModulesBreadcrumb = module?.getModulesBreadcrumb

    const { handleBack } = useHandleBack(getModulesBreadcrumb)

    return (
        <Styled.AppBar
            id="app-bar"
            position="fixed"
            elevation={0}>

            <Styled.HorizontalDiv>
                {
                    (Icon && (module?.alwaysShowIcon || !handleBack)) ?
                        <Icon color="objects/object-black" />
                        : (selectedApp && selectedModule && handleBack) ? (
                            <MobileReturnButton handleBack={handleBack} />
                        )
                            : null
                }
                {
                    module && <Styled.Typography>
                        {t(selectedApp ? l2rApps[selectedApp].i18nNamespace : I18N_NAMESPACE, module.label)}
                    </Styled.Typography>
                }
            </Styled.HorizontalDiv>

            {isAuthenticated && <Styled.WrapperRight>
                <AvatarWithAccountMenu />
            </Styled.WrapperRight>}
        </Styled.AppBar>
    )
}
