import { appCity } from "@l2r-front/l2r-app-city"
import { appRoad } from "@l2r-front/l2r-app-road"
import { appSafety } from "@l2r-front/l2r-app-svrai"

import { getAccountSettingsBreadcrumbs } from "../../modules/accountSettings/getAccountSettingsBreadcrumbs"

import { I18N_NAMESPACE } from "./i18n"

export const l2rApps = {
    l2rServices: {
        index: 0,
        color: "cta-bg/cta-bg-primary",
        i18nNamespace: I18N_NAMESPACE,
        label: null,
        modules: {
            accountSettings: {
                index: 0,
                id: "ACCOUNT_SETTINGS",
                label: "containers.accountMenu.settings",
                path: "/account_settings",
                getModulesBreadcrumb: getAccountSettingsBreadcrumbs,
            },
        },
        path: "",
    },
    city: appCity,
    road: appRoad,
    safety: appSafety,
}
