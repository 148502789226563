import { useCallback, useEffect, useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { GradeBadge } from "../../../../common/components/GradeBadge"
import { YearSelector } from "../../../../common/components/YearSelector"
import { MIRANDA_GRADE_TYPE, NOTATION_GRADE_TYPE } from "../../../../common/constants/urlParams"
import { GLOBAL_GRADE_TYPE } from "../../constants/roadInfoConfig"
import { useRoadwayDispatchContext, useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useRelevantGradings } from "../../hooks/queries/networkGradings/useRelevantGradings"
import { getPreviousNetworkGrading } from "../../utils/networkGradingsUtils"

import { RoadNotationGrade } from "../RoadNotationGrade/RoadNotationGrade"

export function RoadwayDetailYearSelector(props) {

    const {
        className,
    } = props

    const {
        isLoading,
        isError,
        sortedRelevantGradings,
    } = useRelevantGradings()

    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()
    const { setSelectedNetworkYearlyStatus } = useRoadwayDispatchContext()

    useEffect(() => {
        if (sortedRelevantGradings && !selectedNetworkYearlyStatus) {
            const networkGrading = getPreviousNetworkGrading(sortedRelevantGradings, new Date().getFullYear(), false)
            setSelectedNetworkYearlyStatus(networkGrading)
        }
    }, [sortedRelevantGradings, selectedNetworkYearlyStatus, setSelectedNetworkYearlyStatus])

    const yearlyStatusOptions = useMemo(() => {
        if (!sortedRelevantGradings || isLoading || isError) {
            return []
        }
        return sortedRelevantGradings.map(networkGrading => {
            if (networkGrading.type[0] === MIRANDA_GRADE_TYPE) {
                const hasNotationGrading = sortedRelevantGradings.some(grading => {
                    return grading.type.includes(NOTATION_GRADE_TYPE)
                })
                return ({
                    badgeComponent: hasNotationGrading ? <GradeBadge id={`miranda-${networkGrading.year}`} value={networkGrading.year} /> : undefined,
                    date: parseInt(networkGrading.year),
                    disabled: false,
                    value: networkGrading,
                })
            }
            return ({
                badgeComponent: <RoadNotationGrade
                    notationKey={GLOBAL_GRADE_TYPE}
                    notationLayer={networkGrading.layer}
                />,
                date: networkGrading.year,
                disabled: false,
                id: networkGrading.uuid,
                value: networkGrading,
            })
        })
    }, [sortedRelevantGradings, isLoading, isError])

    const selectYear = useCallback((year) => {
        setSelectedNetworkYearlyStatus(year)
    }, [setSelectedNetworkYearlyStatus])

    return (
        <YearSelector
            className={className}
            onClick={selectYear}
            yearlyStatuses={yearlyStatusOptions}
            selectedValue={selectedNetworkYearlyStatus}
        />
    )
}

RoadwayDetailYearSelector.propTypes = {
    className: PropTypes.string,
}