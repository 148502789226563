import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { DAMAGES_URL_PREFIX_PARAM, MIRANDA_GRADE_TYPE, NETWORK_GRADING_PREFIX_PARAM } from "../../../../common/constants/urlParams"
import { LayerIndicator } from "../../components/LayerIndicator/LayerIndicator"
import { mirandaConfig, networkGradeTypesConfig } from "../../constants/roadInfoConfig"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useNetworkConditionForYearlyStatus } from "../../hooks/queries/networkConditions/useNetworkConditionForYearlyStatus"

export function RoadwayLayerIndicator(props) {
    const {
        className,
    } = props

    const { selectedNetworkYearlyStatus, selectedNetworkAssesmentType } = useRoadwayStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { networkCondition } = useNetworkConditionForYearlyStatus(selectedNetworkYearlyStatus)

    const isNotation = useMemo(() => {
        return selectedNetworkAssesmentType?.startsWith(NETWORK_GRADING_PREFIX_PARAM)
    }, [selectedNetworkAssesmentType])

    const infos = useMemo(() => {
        if (!selectedNetworkYearlyStatus) {
            return null
        } else {
            if (isNotation) {
                const gradingKey = selectedNetworkAssesmentType?.replace(NETWORK_GRADING_PREFIX_PARAM, "")
                return t(I18N_NAMESPACE, networkGradeTypesConfig[gradingKey].label)
            } else if (selectedNetworkAssesmentType === MIRANDA_GRADE_TYPE) {
                return t(I18N_NAMESPACE, mirandaConfig.label)
            } else {
                const damageCode = selectedNetworkAssesmentType?.replace(DAMAGES_URL_PREFIX_PARAM, "")
                const damageType = networkCondition?.damageTypes?.find(d => d.code === damageCode)
                if (damageType) {
                    return damageType.name
                } else {
                    return null
                }
            }
        }
    }, [selectedNetworkYearlyStatus, selectedNetworkAssesmentType, networkCondition, t, isNotation])

    return <LayerIndicator className={className} text={infos} />
}