import { MIRANDA_GRADE_TYPE } from "../../../../../common/constants/urlParams"

import { useMirandaStats } from "./useMirandaStats"

export function useRelevantMirandaGradings(queryParameters) {

    const { data, isLoading, isError } = useMirandaStats(queryParameters)

    const sortedRelevantMirandaGradings = Object.entries(data?.grade || {})?.sort(([ngAKey], ngBKey) => {
        return ngAKey - ngBKey
    }).map(([year, value]) => ({ uuid: year, year: parseInt(year), grade: value, type: MIRANDA_GRADE_TYPE }))

    return { isLoading, isError, sortedRelevantMirandaGradings }
}