import { useCallback, useEffect, useMemo, useState } from "react"

import { isEqual } from "lodash"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { layersZindexes } from "../../constants/layers"
import { PADDING_BOUNDING_BOX, FIT_TO_BOUNDING_BOX_DURATION, BASE_MAP_ID } from "../../constants/map"

import { initialStateContext, MapDispatchContext, MapStateContext } from "./MapContext.context"

export const MapContextProvider = (props) => {
    const { children } = props

    const [mapState, setMapState] = useState(initialStateContext)

    const setMapRef = useCallback((mapRef, id = BASE_MAP_ID) => {
        setMapState(value => {
            const mapRefs = value.mapRefs || {}
            mapRefs[id] = mapRef

            return ({
                ...value,
                mapRefs: { ...mapRefs },
            })
        })
    }, [setMapState])

    const getMapRef = useCallback((id = BASE_MAP_ID) => {
        return (mapState.mapRefs || {})[id]
    }, [mapState.mapRefs])

    const setMapBoundingBoxToFit = useCallback((mapBoundingBoxToFit) => {
        setMapState(value => ({
            ...value,
            mapBoundingBoxToFit,
            fitOptions: { padding: PADDING_BOUNDING_BOX, duration: FIT_TO_BOUNDING_BOX_DURATION, essential: true },
        }))
    }, [])

    const storeMapBoundingBox = useCallback(() => {
        const mapRef = getMapRef()
        if (!mapRef) {
            return
        }

        const mapBoundingBox = mapRef.getBounds()
        const boundingBoxToStore = {
            minLng: mapBoundingBox.getSouthWest().lng,
            minLat: mapBoundingBox.getSouthWest().lat,
            maxLng: mapBoundingBox.getNorthEast().lng,
            maxLat: mapBoundingBox.getNorthEast().lat,
        }

        if (!isEqual(boundingBoxToStore, mapState.storedBoundingBox)) {
            setMapState(value => ({
                ...value,
                storedBoundingBox: boundingBoxToStore,
            }))
        }
    }, [getMapRef, mapState.storedBoundingBox, setMapState])

    const resetMapBoundingBox = useCallback(() => {
        setMapState(value => ({
            ...value,
            storedBoundingBox: null,
        }))
    }, [setMapState])

    const restoreMapBoundingBox = useCallback(() => {
        if (mapState.storedBoundingBox) {
            setMapState(value => ({
                ...value,
                mapBoundingBoxToFit: mapState.storedBoundingBox,
                fitOptions: { padding: 0, duration: 0, essential: true },
                storedBoundingBox: null,
            }))
        }
    }, [mapState.storedBoundingBox, setMapState])

    const setCurrentMapStyle = useCallback((newMapStyle) => {
        setMapState(value => ({
            ...value,
            currentMapStyle: newMapStyle,
        }))
    }, [setMapState])

    const setError = useCallback((error) => {
        setMapState(previousMapState => ({
            ...previousMapState,
            error: error,
        }))
    }, [])

    const setSearchRoadCallback = useCallback((callback) => {
        setMapState(previousMapState => ({
            ...previousMapState,
            searchRoadCallback: callback,
        }))
    }, [])

    const toggleLegendOpening = useCallback(() => {
        setMapState(previousMapState => ({
            ...previousMapState,
            legendOpened: !previousMapState.legendOpened,
        }))
    }, [])

    const addLayerClickListener = useCallback((id, callback) => {
        setMapState(previousMapState => ({
            ...previousMapState,
            layerClickListeners: {
                ...previousMapState.layerClickListeners,
                [id]: callback,
            },
        }))
    }, [])

    const removeLayerClickListener = useCallback((id) => {
        setMapState(previousMapState => {
            const { [id]: _, ...otherListeners } = previousMapState.layerClickListeners
            return {
                ...previousMapState,
                layerClickListeners: otherListeners,
            }
        })
    }, [])

    useEffect(() => {
        const mapRef = getMapRef()
        const map = mapRef?.getMap()
        if (!map) {
            return
        }

        const handleLayersClick = (e) => {
            if (!Object.keys(mapState.layerClickListeners).length) {
                return
            }
            const features = map.queryRenderedFeatures(e.point)
            const sortedLayerIds = Object.keys(mapState.layerClickListeners).sort((layerIdA, layerIdB) => {
                return layersZindexes[layerIdA] < layersZindexes[layerIdB] ? -1 : 1
            })
            for (const layerId of sortedLayerIds) {
                const clickedFeature = features.find(feature => feature.layer.id === layerId)
                if (clickedFeature) {
                    mapState.layerClickListeners[layerId]({
                        ...e,
                        feature: clickedFeature,
                    })
                    break
                }
            }
        }

        map.on("click", handleLayersClick)

        return function cleanup() {
            map.off("click", handleLayersClick)
        }
    }, [mapState.layerClickListeners, getMapRef])

    const dispatchValue = useMemo(() => {
        return {
            setMapBoundingBoxToFit,
            storeMapBoundingBox,
            resetMapBoundingBox,
            restoreMapBoundingBox,
            getMapRef,
            setMapRef,
            setCurrentMapStyle,
            setError,
            setSearchRoadCallback,
            toggleLegendOpening,
            addLayerClickListener,
            removeLayerClickListener,
        }
    }, [
        setMapBoundingBoxToFit,
        storeMapBoundingBox,
        resetMapBoundingBox,
        restoreMapBoundingBox,
        getMapRef,
        setMapRef,
        setCurrentMapStyle,
        setError,
        setSearchRoadCallback,
        toggleLegendOpening,
        addLayerClickListener,
        removeLayerClickListener])

    const stateValue = useMemo(() => {
        return ({
            currentMapStyle: mapState.currentMapStyle,
            error: mapState.error,
            fitOptions: mapState.fitOptions,
            legendOpened: mapState.legendOpened,
            mapBoundingBoxToFit: mapState.mapBoundingBoxToFit,
            mapRef: mapState.mapRef,
            storedBoundingBox: mapState.storedBoundingBox,
            searchRoadCallback: mapState.searchRoadCallback,
        })
    }, [mapState])

    return (
        <MapStateContext.Provider value={stateValue}>
            <MapDispatchContext.Provider value={dispatchValue}>
                {children}
            </MapDispatchContext.Provider>
        </MapStateContext.Provider>
    )
}

MapContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
