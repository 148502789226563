import { useMemo } from "react"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { appRoad } from "../constants/appRoad"

export function useGetEnabledRoadModule() {

    const { selectedNetwork } = useNetworksStateContext()

    const enabledRoadModule = useMemo(() => {
        if (selectedNetwork) {
            return Object.values(appRoad.modules)
                .filter(app => app.label)
                .sort((a, b) => a.index - b.index)
                .filter((moduleSettings) => {
                    const enabled = selectedNetwork && selectedNetwork.modules.includes(moduleSettings.id)
                        || moduleSettings?.enablersModules?.some(enablerModule => {
                            return selectedNetwork.modules?.includes(enablerModule)
                        })
                        || moduleSettings?.mandatoryModules?.every(mandatoryModule => {
                            return selectedNetwork.modules?.includes(mandatoryModule)
                        })
                    return enabled
                })
        }
        return []
    }, [selectedNetwork])

    return enabledRoadModule
}