import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useLocalStorage } from "@uidotdev/usehooks"
import { useLocation, useMatch, useNavigate } from "react-router-dom"

import { Trans, useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { L2RPopover, Typography } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { l2rApps } from "../../constants/l2rApps"

import * as Styled from "./ModuleSelector.styled"

const HoverableTab = ({ onMouseEnter, onMouseLeave, ...props }) => {
    return <Styled.TabContainer
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        <Styled.Tab {...props} />
    </Styled.TabContainer>
}

export const ModuleSelector = (props) => {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { selectedNetwork } = useNetworksStateContext()
    const { storeMapBoundingBox } = useMapDispatchContext()
    const navigate = useNavigate()
    const match = useMatch("/:selectedNetwork/:app/:module/*")
    const selectedApp = match?.params?.app
    const selectedModule = match?.params?.module
    const location = useLocation()

    const [_, setLastConsultedModules] = useLocalStorage("lastConsultedModules", {})
    const [popoverInfos, setPopoverInfos] = useState(null)

    const handlePopoverOpen = useCallback((event, app, module) => {

        const isActiveModule = getIsActiveModule(module, selectedNetwork)
        const displayPopover = module.hidePopover || isActiveModule

        if (!displayPopover) {
            setPopoverInfos({
                anchor: event.currentTarget,
                moduleName: t(app.i18nNamespace, module.label),
            })
        }
    }, [selectedNetwork, t])

    const handlePopoverClose = useCallback(() => {
        setPopoverInfos(prev => ({
            ...prev,
            anchor: null,
        }))
    }, [])

    const app = useMemo(() => {
        const filteredApps = Object.values(l2rApps)
            .filter(app => app.label)
            .sort((a, b) => a.index - b.index)

        return Object.values(l2rApps).find(app => app.path === selectedApp) || filteredApps[0]
    }, [selectedApp])

    const modules = useMemo(() => {
        if (!app || !selectedNetwork) {
            return []
        }
        return Object.values(app.modules).map((module) => {
            const Icon = module.icon
            const isActiveModule = getIsActiveModule(module, selectedNetwork)
            return {
                key: module.id,
                value: module.path,
                label: t(app.i18nNamespace, module.label),
                icon: module.alwaysShowIcon ? <Icon sx={{ fontSize: "medium" }} /> : null,
                iconPosition: "start",
                active: isActiveModule,
                props: {
                    onMouseEnter: (e) => handlePopoverOpen(e, app, module),
                    onMouseLeave: handlePopoverClose,
                },
            }
        })
    }, [app, selectedNetwork, t, handlePopoverClose, handlePopoverOpen])

    const changeSelectedModule = useCallback((_, value) => {
        storeMapBoundingBox()

        const previousPath = match?.params?.["*"].length ? match?.params?.["*"] : ""
        const fullPath = `/${selectedNetwork.slug}/${app?.path}/${value}/${previousPath}${location?.search ? location.search : ""}`
        return navigate(fullPath)
    }, [location, match, navigate, selectedNetwork, app, storeMapBoundingBox])

    useEffect(() => {
        setLastConsultedModules((prev) => (
            {
                ...prev,
                [app?.path]: selectedModule,
            }
        ))
    }, [selectedModule, app, setLastConsultedModules])

    if (!selectedModule) {
        return <Styled.EmptyContainer />
    }

    const open = Boolean(popoverInfos?.anchor)

    return <>
        <Styled.Tabs
            value={selectedModule}
            handleChangeTab={changeSelectedModule}
            tabElement={HoverableTab}
            tabsAttributes={modules}
            variant="scrollable"
            scrollButtons="auto"
            {...props} />
        <L2RPopover
            id="mouse-over-popover"
            open={open}
            anchorEl={popoverInfos?.anchor}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus>
            <Typography variant="Small">
                <Trans namespace={I18N_NAMESPACE} i18nKey="containers.moduleSelector.moduleNotSubscribed">
                    "Module {{ moduleName: popoverInfos?.moduleName }} non souscrit "
                </Trans>
            </Typography>
        </L2RPopover>
    </>
}

function getIsActiveModule(module, selectedNetwork) {
    if (module.mandatoryModules) {
        return module.mandatoryModules.every(mandatoryModule => selectedNetwork.modules.includes(mandatoryModule))
    }
    return selectedNetwork.modules.includes(module.id) || module.alwaysEnabled
        || module.enablersModules?.some(enablerModule => selectedNetwork.modules.includes(enablerModule))

}