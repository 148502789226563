import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"

import { roadworksQueryKeys } from "./queryKeys"

export function useRoadworksStats(
    queryParameters,
    config = {},
) {

    const url = "roadworks/stats/"
    const queryKey = roadworksQueryKeys["stats"](queryParameters)

    return useRoadworksQuery(
        url,
        queryKey,
        queryParameters,
        { ...config },
    )
}