import { Grid as UiGrid, styled, styledForMobile, Typography as UiTypography } from "@l2r-front/l2r-ui"

export const GRID_SPACING = 5

export const Grid = styledForMobile(UiGrid)(({ theme }) => ({
    height: "fit-content",
    marginTop: 0,
    marginBottom: theme.spacing(GRID_SPACING),

    "& > div:nth-of-type(-n + 2)": {
        paddingTop: "0 !important",
    },
}))

export const Scrollable = styled("div")({
    display: "flex",
    flex: "1 1 auto",
})

export const GridItem = styled(UiGrid)(() => ({
    display: "flex",
    justifyContent: "center",
}))

export const TotalCost = styled(UiTypography)(({ theme }) => ({
    marginBottom: theme.spacing(5),
    marginLeft: theme.spacing(5),
}))

export const TotalCostContainer = styled("div")(() => ({
    display: "flex",
    flexDirection: "row",
}))