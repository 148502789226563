import { ArrowBackIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./MobileReturnButton.styled"

export const MobileReturnButton = (props) => {

    const {
        handleBack,
    } = props

    return <Styled.Button id="back-arrow" color="objects/object-black" onClick={handleBack}>
        <ArrowBackIcon color="objects/object-black" />
    </Styled.Button>
}

MobileReturnButton.propTypes = {
    handleBack: PropTypes.func.isRequired,
}