import { useEffect, useMemo } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapContext } from "@l2r-front/l2r-map"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"


import { CANVAS_MIN_BBOX_WIDTH } from "../../constants/bboxMinWidth"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { useNetworksStateContext } from "../../contexts/NetworksContext"
import { networksQueryKeys } from "../../hooks"

export const MapAdapterRoad = () => {

    const { t } = useTranslation()
    const { setSnackbar } = useAlertsDispatchContext()
    const navigate = useNavigateWithSearchParams()
    const { road } = useParams()
    const [mapState, mapDispatch] = useMapContext()
    const { getMapRef, setMapBoundingBoxToFit, restoreMapBoundingBox } = mapDispatch
    const { storedBoundingBox } = mapState
    const mapRef = getMapRef()
    const { selectedNetwork } = useNetworksStateContext()

    const queryKeyParams = { road }
    const { data: roadSegments, isError, isLoading, refetch } = useGeoServerFeaturesList(
        networksQueryKeys.list(queryKeyParams),
        {
            layer: selectedNetwork?.referential?.layerSection,
            linear_location_road: road,
        },
        {
            enabled: !!road && !!selectedNetwork?.referential,
        })

    useEffect(() => {
        if (road && selectedNetwork) {
            refetch()
        }
    }, [road, selectedNetwork, refetch])

    const roadBoundingBox = useMemo(() => {
        if (isLoading) {
            return null
        }
        if (!roadSegments || !roadSegments.length) {
            const [minLng, minLat, maxLng, maxLat] = turf.bbox(selectedNetwork.boundingBox)
            return {
                minLng,
                minLat,
                maxLng,
                maxLat,
            }
        }

        const roadFeature = turf.featureCollection(roadSegments)
        const [minLng, minLat, maxLng, maxLat] = turf.bbox(roadFeature)
        const lngOffset = Math.max((CANVAS_MIN_BBOX_WIDTH - (maxLng - minLng)), 0) / 2
        return ({
            minLng: minLng - lngOffset,
            minLat,
            maxLng: maxLng + lngOffset,
            maxLat,
        })
    }, [roadSegments, selectedNetwork, isLoading])

    useEffect(() => {
        if (!isLoading && (!roadSegments?.length || isError)) {
            setSnackbar({
                autoHideDuration: 5000,
                label: t(I18N_NAMESPACE, "containers.mapAdapterSegment.error"),
                severity: "error",
                anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom",
                },
            })

            return navigate("..")
        }
        return
    }, [roadSegments, isError, isLoading, navigate, setSnackbar, t])

    useEffect(() => {
        if (mapRef) {
            if (storedBoundingBox) {
                restoreMapBoundingBox()
            } else if (roadBoundingBox) {
                setMapBoundingBoxToFit(roadBoundingBox)
            }
        }
    }, [mapRef,
        roadBoundingBox,
        setMapBoundingBoxToFit,
        storedBoundingBox,
        restoreMapBoundingBox,
    ])

    return null
}