import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useLocalStorage } from "@uidotdev/usehooks"
import { useLocation, useMatch, useNavigate } from "react-router-dom"

import { Trans, useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { L2RPopover, Typography, useMediaQuery, useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { l2rApps } from "../../constants/l2rApps"

import * as Styled from "./AppSelector.styled"

export const AppSelector = (props) => {
    const {
        className,
    } = props

    const { selectedNetwork } = useNetworksStateContext()
    const { storeMapBoundingBox } = useMapDispatchContext()
    const navigate = useNavigate()
    const match = useMatch("/:selectedNetwork/:app/:module/*")
    const location = useLocation()
    const selectedApp = match?.params?.app
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"))

    const [lastConsultedModules] = useLocalStorage("lastConsultedModules", {})
    const [popoverInfos, setPopoverInfos] = useState(null)
    const { t } = useTranslation(I18N_NAMESPACE)

    const apps = useMemo(() => {
        return Object.values(l2rApps)
            .filter(app => app.label)
            .sort((a, b) => a.index - b.index)
            .map((app) => {
                const appModulesSettings = Object.values(app.modules)
                const appModules = appModulesSettings.map(module => module.id)
                const enabled = selectedNetwork && selectedNetwork.modules.some(module => {
                    return appModules.includes(module) || appModulesSettings.some(appModule => {
                        return appModule?.enablersModules?.includes(module)
                    })
                })
                return {
                    ...app,
                    enabled,
                }
            })
    }, [selectedNetwork])

    const navigateToApp = useCallback((appPath) => {
        const app = apps.find(app => app.path === appPath)
        const cachedModuleId = lastConsultedModules?.[app.path]
        const cachedModule = app.modules[cachedModuleId]
        const firstEnabledModule = Object.values(app.modules)
            .sort((a, b) => a.index - b.index)
            .find(module => {
                if (module.mandatoryModules) {
                    return module?.mandatoryModules?.every(mandatoryModule => {
                        return selectedNetwork.modules?.includes(mandatoryModule)
                    })
                } else {
                    return module.alwaysEnabled || selectedNetwork.modules.includes(module.id) || selectedNetwork.modules.some(selectedModule => {
                        return module?.enablersModules?.includes(selectedModule)
                    })
                }
            })
        const selectedModule = cachedModule || firstEnabledModule
        const previousPath = match?.params?.["*"].length ? match?.params?.["*"] : ""
        const fullPath = `/${selectedNetwork.slug}/${app.path}/${selectedModule?.path}/${previousPath}${location?.search ? location.search : ""}`
        navigate(fullPath)

    }, [apps, selectedNetwork, location, match, lastConsultedModules, navigate])

    useEffect(() => {
        if (!selectedApp) {
            const firstEnabledApp = apps.find(app => app?.enabled)
            if (firstEnabledApp?.path) {
                navigateToApp(firstEnabledApp.path)
            }
        }
    }, [selectedApp, navigate, navigateToApp, apps])

    const handleAppChanged = useCallback((_, appPath) => {
        if (appPath && appPath !== selectedApp) {
            storeMapBoundingBox()
            navigateToApp(appPath)
        }
    }, [navigateToApp, storeMapBoundingBox, selectedApp])

    const handlePopoverOpen = useCallback((event, app) => {
        if (!app.enabled) {
            setPopoverInfos({
                anchor: event.currentTarget,
                appName: t(app.i18nNamespace, app.label),
            })
        }
    }, [t])

    const handlePopoverClose = useCallback(() => {
        setPopoverInfos(prev => ({
            ...prev,
            anchor: null,
        }))
    }, [])

    const open = Boolean(popoverInfos?.anchor)

    if (!selectedApp) {
        return <div className={className} />
    }

    return (
        <Styled.Container
            className={className}
            value={selectedApp}
            onChange={handleAppChanged}
            disabled={!selectedApp}
            exclusive>
            {
                apps.map(app => {
                    const Icon = app.icon
                    return <div
                        key={app.path}
                        onMouseEnter={(e) => handlePopoverOpen(e, app)}
                        onMouseLeave={handlePopoverClose}>
                        <Styled.AppButton
                            key={app.path}
                            value={app.path}
                            disabled={!app.enabled}
                        >
                            {app.icon && <Icon />}
                            {isLargeScreen && t(app.i18nNamespace, app.label)}
                        </Styled.AppButton>
                    </div>
                })
            }
            <L2RPopover
                id="mouse-over-popover"
                open={open}
                anchorEl={popoverInfos?.anchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus>
                <Typography variant="Small">
                    <Trans namespace={I18N_NAMESPACE} i18nKey="containers.appSelector.appNotSubscribed">
                        "Module {{ appName: popoverInfos?.appName }} non souscrit "
                    </Trans>
                </Typography>
            </L2RPopover>
        </Styled.Container >
    )
}