import { Autocomplete as UiAutocomplete, TextField, styled } from "@l2r-front/l2r-ui"

export const Autocomplete = styled(UiAutocomplete, { shouldForwardProp: (prop) => prop !== "large" })(({ large }) => ({
    width: large ? 330 : 200,
    height: 40,

    "& > .MuiFormControl-root": {
        height: "100%",
    },
}))

export const Input = styled(TextField)(({ theme }) => ({

    "& .MuiInputBase-root": {
        backgroundColor: theme.palette["colors/vert logiroad/600"].main,
        borderRadius: theme.spacing(3),
        color: "white",
        height: "100%",
        fontWeight: 600,
        padding: theme.spacing(0, 9, 0, 3),

        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },

        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                color: "white",
            },
        },
    },
}))
