import ReactPropTypes from "prop-types"

import { PropTypes as CustomPropTypes } from "./propTypes"
export const PropTypes = {
    ...ReactPropTypes,
    ...CustomPropTypes,
}

export { allowEmptyString } from "./utils/allowEmptyString"

export * from "./interfaces"