export const getAccountSettingsBreadcrumbs = () => {

    return [
        {
            path: "/account_settings",
            newPath: "/",
        },
        {
            path: "/account_settings/profile",
            newPath: "/account_settings",
        },
        {
            path: "/account_settings/email",
            newPath: "/account_settings",
        },
        {
            path: "/account_settings/password",
            newPath: "/account_settings",
        },
    ]
}