import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ColoredBadge } from "@l2r-front/l2r-ui"

import { GradeBadge } from "../../../../common/components/GradeBadge"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { mirandaLegend } from "../../constants/getNotationConfig"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useRelevantMirandaGradings } from "../../hooks/queries/miranda/useRelevantMirandaGradings"

import * as Styled from "./MirandaGrade.styled"

export function MirandaGrade() {

    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()

    const {
        isLoading,
        isError,
        sortedRelevantMirandaGradings,
    } = useRelevantMirandaGradings()

    const { t } = useTranslation(I18N_NAMESPACE)

    const grade = useMemo(() => {
        if (sortedRelevantMirandaGradings && selectedNetworkYearlyStatus?.year) {
            const networkGrading = sortedRelevantMirandaGradings.find(ng => {
                return ng.year === selectedNetworkYearlyStatus.year
            })
            return networkGrading?.grade ? parseFloat(networkGrading.grade) : null
        }

        return null
    }, [sortedRelevantMirandaGradings, selectedNetworkYearlyStatus])

    if (isLoading || (!sortedRelevantMirandaGradings && !isError)) {
        return <Styled.Skeleton />
    }

    if (isError || sortedRelevantMirandaGradings === null) {
        return <ColoredBadge
            backgroundColor="surfaces/surface-error"
            text={t(I18N_NAMESPACE, "containers.weightedAverageNotationGrade.error")}
            textVariant="H3"
        />
    }

    return <GradeBadge grade={grade} legend={mirandaLegend} />
}
