import {
    APPBAR_HEIGHT,
    APPBAR_WITHOUT_MODULES_HEIGHT,
    Tab as UiTab,
    Tabs as L2rTabs,
    styled,
} from "@l2r-front/l2r-ui"

export const Tabs = styled(L2rTabs)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    height: APPBAR_HEIGHT - APPBAR_WITHOUT_MODULES_HEIGHT,

    "& .MuiTabs-scrollButtons": {
        color: "black",
    },
}))

export const TabContainer = styled("div")(() => ({
    lineHeight: "44px",

    "&:first-of-type": {
        paddingLeft: 14,
    },
}))

export const Tab = styled(UiTab)(({ theme }) => ({
    color: theme.palette["primary"].main,
    minHeight: 0,
    textTransform: "none",
    fontSize: theme.typography["H3"].fontSize,
    fontWeight: theme.typography["H3"].fontWeight,
}))

export const EmptyContainer = styled("div")(() => ({
    width: "100%",
    height: APPBAR_HEIGHT - APPBAR_WITHOUT_MODULES_HEIGHT,
}))