import { HomeIcon as UiHomeIcon } from "@l2r-front/l2r-icons"
import { APPBAR_WITHOUT_MODULES_HEIGHT, Button as UiButton, Typography as UiTypography, styled } from "@l2r-front/l2r-ui"

import { AppBar as AppBarComponent } from "../AppBar"

export const WrapperRight = styled("div")({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    position: "relative",
})

export const Logo = styled("img")(() => ({
    height: 40,
}))

export const WrapperLeft = styled("div")(() => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "relative",
}))

export const Button = styled(UiButton)({
    textTransform: "initial",
})

export const AppBar = styled(AppBarComponent)(({ theme }) => ({
    height: APPBAR_WITHOUT_MODULES_HEIGHT,
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
}))

export const HomeIcon = styled(UiHomeIcon)(({ theme }) => ({
    margin: theme.spacing(1),
}))

export const HorizontalDiv = styled("div")({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
})
export const Typography = styled(UiTypography)(({ theme }) => ({
    color: theme.palette["texts/text-primary"].main,
    width: "auto",
    marginLeft: theme.spacing(8),
}))
