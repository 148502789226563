import { useTranslation } from "@l2r-front/l2r-i18n"
import { useIsMobileDevice, useMediaQuery, useTheme } from "@l2r-front/l2r-ui"

import { MobileBottomAppBar } from "../../components/MobileBottomAppBar"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { AvatarWithAccountMenu } from "../../containers/AvatarWithAccountMenu"
import { SimpleAppBar } from "../../containers/SimpleAppBar"

import * as Styled from "./EmptyPage.styled"

export function EmptyPage() {

    const isMobile = useIsMobileDevice()
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"))
    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.PageContainer>
        {isMobile ? <SimpleAppBar /> : <Styled.AppBar>
            <Styled.Link>
                {
                    isLargeScreen ? <Styled.Logo id="logiroad-logo" src={"assets/resources/logiroad-center-logo.svg"} />
                        : <Styled.Logo id="logiroad-favicon" src={"assets/resources/Favicon_Logiroad.svg"} />
                }

            </Styled.Link>
            <Styled.NetworkSelector id="network-selector" />
            <AvatarWithAccountMenu />
        </Styled.AppBar>}
        <Styled.ImageContainer src="assets/resources/emptyImage.png" alt="Empty page">
            <Styled.TextContainer isMobile={isMobile}>
                <Styled.Typography variant="H1">
                    {t(I18N_NAMESPACE, "pages.emptyPage.title")}
                </Styled.Typography>
                <Styled.Typography variant="H2">
                    {t(I18N_NAMESPACE, "pages.emptyPage.subtitle")}
                </Styled.Typography>
            </Styled.TextContainer>
        </Styled.ImageContainer>
        {isMobile && <MobileBottomAppBar />}
    </Styled.PageContainer>
}