import { queryKeyFactory } from "@l2r-front/l2r-query"

export const NETWORK_GRADING_ROOT_QUERY_KEY = "network_grading"
export const ROADWORKS_ROOT_QUERY_KEY = "roadworks"

export const roadworksQueryKeys = queryKeyFactory(ROADWORKS_ROOT_QUERY_KEY, {
    project: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "project", filters] as const,
    quartersStats: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "quarters_stats", filters] as const,
    network: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "network", filters] as const,
    layer: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "layer", filters] as const,
    linearLocations: (segmentUuid) => [ROADWORKS_ROOT_QUERY_KEY, "linearLocations", segmentUuid] as const,
    roadwork: (roadworkUuid) => [ROADWORKS_ROOT_QUERY_KEY, "roadwork", roadworkUuid] as const,
    stats: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "stats", filters] as const,
    stats_tasks: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "stats_tasks", filters] as const,
    stats_techniques: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "stats_techniques", filters] as const,
    task: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "task", filters] as const,
    taskType: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "taskType", filters] as const,
    techniques: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "techniques", filters] as const,
    years_stats: (filters = {}) => [ROADWORKS_ROOT_QUERY_KEY, "years_stats", filters] as const,
    section: (segmentUuid) => [ROADWORKS_ROOT_QUERY_KEY, "section", segmentUuid] as const,
})

export const networkGradingQueryKeys = queryKeyFactory(NETWORK_GRADING_ROOT_QUERY_KEY, {
    project: (filters = {}) => [NETWORK_GRADING_ROOT_QUERY_KEY, "project", filters] as const,
})