import { RoadDetailInfo } from "@l2r-front/l2r-networks"
import {
    styled,
    styledForMobile,
    IconButton,
    Typography,
} from "@l2r-front/l2r-ui"

import { RoadworkDetailsFormPost } from "../../forms/RoadworkDetailsForm"

export const Container = styledForMobile("div")(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "auto",
}))

export const LocationHeader = styled("div")(({ isMobile, theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: isMobile ? theme.spacing(0, 4) : theme.spacing(0, 8),
}))

export const FilledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    color: "white",
    borderRadius: theme.spacing(3),

    "&:hover": {
        backgroundColor: theme.palette["cta-bg/cta-bg-primary"].dark,
    },
}))

export const SegmentDetailInfo = styled(RoadDetailInfo)(({ isMobile, theme }) => ({
    margin: 0,
    padding: isMobile ? theme.spacing(0, 4) : theme.spacing(0, 8),
}))

export const NetworkGradingSelectorDescription = styled(Typography)(({ theme }) => ({
    display: "block",
    marginTop: theme.spacing(5),
    width: "100%",
}))

export const RoadworkDetailsForm = styled(RoadworkDetailsFormPost)(() => ({
    flex: 1,
}))

export const Header = styledForMobile("div")(({ theme, isMobile }) => ({
    paddingBottom: isMobile ? theme.spacing(6) : theme.spacing(6),
}))