import { Link as L2RLink } from "react-router-dom"

import { APPBAR_WITHOUT_MODULES_HEIGHT, SIDEBAR_WIDTH, styled, styledForMobile } from "@l2r-front/l2r-ui"

import { AppBar as L2RAppBar } from "../../components/AppBar"

export const AppBar = styledForMobile(L2RAppBar)(({ isMobile, theme }) => ({
    backgroundColor: isMobile ? theme.palette["surfaces/surface-primary"].main : theme.palette["components/app-bar/button/default/main"].main,
    height: APPBAR_WITHOUT_MODULES_HEIGHT,
    zIndex: theme.zIndex.drawer + 2,
}))

export const WrapperRight = styled("div")({
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    position: "relative",
    width: "100%",
})

export const Logo = styled("img")(() => ({
    height: 36,
}))

export const WrapperLeft = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    height: "100%",
    position: "relative",
    width: `calc(${SIDEBAR_WIDTH}px - ${theme.spacing(9)})`,
}))

export const Link = styled(L2RLink)({
    alignItems: "center",
    display: "flex",
    height: "100%",
})