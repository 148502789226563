import { IncidentIcon, SafetyIcon } from "@l2r-front/l2r-icons"

import { L2rAppSvrai } from "../../app"

import { ProjectContextProvider } from "../contexts/ProjectContext"
import { getModulesBreadcrumb } from "../utils/getModulesBreadcrumb"

import { I18N_NAMESPACE } from "./i18n"
import { MODULE_INCIDENTS } from "./modules"

export const APP_SAFETY = "safety"

export const appSafety = {
    index: 3,
    color: "cta-bg/cta-bg-primary",
    component: <L2rAppSvrai />,
    icon: SafetyIcon,
    desktopCustomLayoutRoutes: [`/${APP_SAFETY}/${MODULE_INCIDENTS}/:eventId/analysis`],
    externalContexts: <ProjectContextProvider />,
    i18nNamespace: I18N_NAMESPACE,
    label: "app.label",
    modules: {
        incidents: {
            index: 0,
            icon: IncidentIcon,
            id: "INCIDENTS",
            label: "app.modules.incidents",
            path: MODULE_INCIDENTS,
            getModulesBreadcrumb,
        },
    },
    path: APP_SAFETY,
}