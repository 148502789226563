export const DAMAGES_URL_PREFIX_PARAM = "damages-"
export const NETWORK_ASSESMENT_TYPE_URL_PARAM = "type"
export const NETWORK_GRADING_PREFIX_PARAM = "grading-"
export const MIRANDA_GRADE_TYPE = "miranda"
export const NOTATION_GRADE_TYPE = "notation"
export const NETWORK_GRADING_URL_PARAM = "networkGrading"
export const NETWORK_ROADWORKS_URL_PARAM = "roadworks"
export const NETWORK_PREFIXES = [DAMAGES_URL_PREFIX_PARAM, NETWORK_GRADING_PREFIX_PARAM]
export const PARAM_KEY_ROADWAY = "tab-roadway"
export const PARAM_KEY_ROADWAY_DETAIL = "tab-roadwayDetail"
export const PARAM_KEY_HORIZONTAL_SIGNING = "tab-horizontalSigning"
