import { union } from "lodash"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { MIRANDA_GRADE_TYPE, NOTATION_GRADE_TYPE } from "../../../../../common/constants/urlParams"

import { useRelevantMirandaGradings } from "../miranda/useRelevantMirandaGradings"

import { useRelevantNetworkGradings } from "./useRelevantNetworkGradings"

export function useRelevantGradings(queryParameters, config = {}) {

    const { selectedNetwork } = useNetworksStateContext()

    const { isLoadingNetworkGrading, isErrorNetworkGradings, sortedRelevantNetworkGradings } = useRelevantNetworkGradings(selectedNetwork?.slug, queryParameters, config)
    const { isLoadingMirandaGradings, isErrorMirandaGradings, sortedRelevantMirandaGradings } = useRelevantMirandaGradings(selectedNetwork?.slug)

    const isError = isErrorNetworkGradings || isErrorMirandaGradings
    const isLoading = isLoadingNetworkGrading || isLoadingMirandaGradings

    const relevantGradings = union(sortedRelevantNetworkGradings?.map(v => ({ ...v, type: NOTATION_GRADE_TYPE })), sortedRelevantMirandaGradings?.map(v => ({ ...v, type: MIRANDA_GRADE_TYPE })))

    const sortedRelevantGradings = relevantGradings.reduce((acc, v) => {
        const existingYearIndex = acc.findIndex(item => parseInt(item.year) === parseInt(v.year))

        if (existingYearIndex === -1) {
            acc.push({
                ...v,
                type: [v.type],
            })
        } else {
            acc[existingYearIndex].type = Array.isArray(acc[existingYearIndex].type)
                ? [...acc[existingYearIndex].type, v.type]
                : [acc[existingYearIndex].type, v.type]
        }
        return acc
    }, [])
        .sort((a, b) => parseInt(a.year) - parseInt(b.year))

    return { isLoading, isError, sortedRelevantGradings }
}