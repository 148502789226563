import { PropTypes } from "@l2r-front/l2r-proptypes"

import { SimpleAppBar } from "../../containers/SimpleAppBar"

import * as Styled from "./MessagePage.styled"

export const MessagePage = (props) => {

    const {
        children,
    } = props

    return (
        <Styled.PageContainer>
            <SimpleAppBar />
            {children}
        </Styled.PageContainer>
    )
}

MessagePage.propTypes = {
    children: PropTypes.node,
}