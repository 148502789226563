import { APPBAR_WITHOUT_MODULES_HEIGHT, styled } from "@l2r-front/l2r-ui"

export const ContentWrapper = styled("div")({
    boxSizing: "border-box",
    display: "flex",
    height: "100%",
    justifyContent: "space-around",
    paddingTop: APPBAR_WITHOUT_MODULES_HEIGHT,
    position: "relative",
    width: "100%",
})

export const PageWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["objects/object-lightgrey"].main,
    display: "flex",
    height: "100%",
    overflowY: "auto",
    width: "100%",
}))

export const Image = styled("img")({
    height: "100%",
    width: "100%",
    objectFit: "cover",
})