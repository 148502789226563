import { useCallback, useEffect, useMemo, useState } from "react"

import { getYear } from "date-fns"
import { useMatch } from "react-router-dom"
import { useEffectOnce } from "react-use"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useBlockingNavigate } from "@l2r-front/l2r-query"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { MODULE_ROADWORKS } from "../../../../common/constants/modules"
import { useNetworkGradingsForMultipleSurveysQuery } from "../../../roadway/hooks/queries/networkGradings/useNetworkGradings"
import { useSurveysQuery } from "../../../roadway/hooks/queries/surveys/useSurveys"
import { STATUSES_FILTER, YEARS_FILTER } from "../../constants/filters"
import { STATUS_TODO } from "../../constants/status"
import { SELECTED_ROADWORK_URL_PARAM, SELECTED_TECHNIQUE_URL_PARAM } from "../../constants/techniques"
import { useGetProjectTechniqueCodes } from "../../hooks/queries/useGetProjectTechniqueCodes"
import { useRoadworksProject } from "../../hooks/queries/useRoadworksProjectQuery"
import { compareFilters } from "../../utils/compareFilters"
import { roadworksFiltersConfig } from "../../utils/roadworksFiltersConfig"

import { RoadworksDispatchContext, RoadworksStateContext, initialState } from "./RoadworksContext.context"
import { getFiltersFromSearchParams, getSearchParamsFromFilters, setFiltersSearchParams } from "./RoadworksContext.helpers"

export function RoadworksContextProvider(props) {
    const { children } = props
    const [roadworkState, setRoadWorksState] = useState(initialState)
    const matchRoadworks = useMatch(`:slug/road/${MODULE_ROADWORKS}/*`)
    const matchRoad = useMatch(`:slug/road/${MODULE_ROADWORKS}/:road/*`)

    const { setImageCollectionCurrentYear } = useImageCollectionDispatchContext()
    const { setSelectedLinearLocations } = useNetworksDispatchContext()
    const { data: techniqueCodes } = useGetProjectTechniqueCodes({ enabled: !!matchRoadworks })

    const setStateSelectedSegment = useCallback((segment) => {
        setRoadWorksState(value => ({
            ...value,
            selectedSegmentUuid: segment?.properties?.uuid || segment?.uuid || null,
        }))
    }, [])

    const registerSelectedSegment = useCallback((segment) => {
        setStateSelectedSegment(segment)
        setSelectedLinearLocations(segment)
    }, [setStateSelectedSegment, setSelectedLinearLocations])

    const setSelectedSegment = useBlockingNavigate(
        setStateSelectedSegment,
        registerSelectedSegment,
    )

    const {
        deleteParam,
        getParam,
        getParams,
        setParam,
    } = useUrlParams()

    const {
        data: surveys,
        isLoading: isLoadingSurveys,
        isError: isErrorSurveys,
    } = useSurveysQuery()

    const { data: project } = useRoadworksProject()
    const roadworksQueryResults = useNetworkGradingsForMultipleSurveysQuery(surveys ?? [], {}, { enabled: !!surveys })

    const isLoadingRoadworks = useMemo(() => {
        return isLoadingSurveys || roadworksQueryResults.some((roadworksQueryResult) => roadworksQueryResult.isLoading)
    }, [isLoadingSurveys, roadworksQueryResults])

    const isErrorRoadworks = useMemo(() => {
        return isErrorSurveys || roadworksQueryResults.reduce(
            (isSomethingError, roadworksQueryResult) => roadworksQueryResult.isError || isSomethingError,
            false)
    }, [isErrorSurveys, roadworksQueryResults])

    const getSurveyForWorks = useCallback((work) => {
        if (!work || !surveys || !surveys.length) {
            return null
        }

        return surveys.find((survey) => {
            return survey.uuid === work.survey
        })
    }, [surveys])

    const setSelectedTechnique = useCallback((value) => {
        if (roadworkState?.selectedTechnique !== value) {
            setRoadWorksState(roadworkState => ({
                ...roadworkState,
                selectedTechnique: value,
            }))
            if (value) {
                setParam(SELECTED_TECHNIQUE_URL_PARAM, value)
                return
            }
            deleteParam(SELECTED_TECHNIQUE_URL_PARAM)
            return
        }

        setRoadWorksState(roadworkState => ({
            ...roadworkState,
            selectedTechnique: null,
        }))
        deleteParam(SELECTED_TECHNIQUE_URL_PARAM)
    }, [deleteParam, roadworkState, setParam])

    const setSelectedRoadwork = useCallback((value) => {
        setRoadWorksState(roadworkState => ({
            ...roadworkState,
            selectedRoadwork: value,
        }))

        if (value) {
            setParam(SELECTED_ROADWORK_URL_PARAM, value)
        } else {
            deleteParam(SELECTED_ROADWORK_URL_PARAM)
        }
        return
    }, [deleteParam, setParam])

    const setImageCollectionYearFromFilters = useCallback(filters => {
        if (filters[YEARS_FILTER]?.length) {
            const maxFilterYear = Math.max(...filters[YEARS_FILTER])
            setImageCollectionCurrentYear(maxFilterYear)
        } else if (project?.roadworksYears?.length) {
            const maxYear = Math.max(...project.roadworksYears)
            setImageCollectionCurrentYear(maxYear)
        }
    }, [setImageCollectionCurrentYear, project?.roadworksYears])

    const setFilters = useCallback((newFilters) => {
        setRoadWorksState(value => ({
            ...value,
            filters: newFilters,
        }))

        if (matchRoadworks?.params.slug) {
            setFiltersSearchParams(newFilters, setParam, deleteParam)
        }
    }, [matchRoadworks?.params.slug, deleteParam, setParam])

    const applyFilters = useCallback((filters) => {
        if (!compareFilters(filters, roadworkState.filters)) {
            setSelectedTechnique(null)
            setSelectedRoadwork(null)
            setFilters(filters)
            setImageCollectionYearFromFilters(filters)
        }
    }, [setSelectedTechnique,
        setSelectedRoadwork,
        setFilters,
        setImageCollectionYearFromFilters,
        roadworkState.filters])

    const apiFilters = useMemo(() => {
        return Object.entries(roadworkState.filters || {}).reduce((acc, [filterKey, filterValue]) => {
            if (matchRoad && filterKey === STATUSES_FILTER) {
                return acc
            }
            const isMultipleValue = Array.isArray(filterValue) && filterValue.length > 1
            const queryParam = isMultipleValue ? roadworksFiltersConfig[filterKey]?.inQueryParam : roadworksFiltersConfig[filterKey]?.queryParam
            if (queryParam) {
                return {
                    ...acc,
                    [queryParam]: filterValue,
                }
            } else {
                return acc
            }
        }, {})
    }, [matchRoad, roadworkState.filters])

    useEffect(() => {
        if (matchRoadworks) {
            const searchParamsSelectedTechnique = getParam(SELECTED_TECHNIQUE_URL_PARAM)

            if (searchParamsSelectedTechnique) {
                setRoadWorksState(roadworkState => ({
                    ...roadworkState,
                    selectedTechnique: searchParamsSelectedTechnique,
                }))
            }

            const searchParamsSelectedRoadwork = getParam(SELECTED_ROADWORK_URL_PARAM)

            if (searchParamsSelectedRoadwork) {
                setRoadWorksState(roadworkState => ({
                    ...roadworkState,
                    selectedRoadwork: searchParamsSelectedRoadwork,
                }))
            }
        }
    }, [getParam, matchRoadworks])

    useEffectOnce(() => {
        const searchParamsSelectedTechnique = getParam(SELECTED_TECHNIQUE_URL_PARAM)
        const searchParamsSelectedRoadwork = getParam(SELECTED_ROADWORK_URL_PARAM)
        const filtersFromParams = getFiltersFromSearchParams(getParams)

        const filtersInParams = !!Object.keys(filtersFromParams).length
        if (!filtersInParams && !searchParamsSelectedTechnique && !searchParamsSelectedRoadwork) {
            const nowYear = getYear(Date.now())
            setFilters(
                {
                    ...roadworkState.filters,
                    years: [nowYear],
                    statuses: [STATUS_TODO],
                })
            return
        }
    })

    useEffect(() => {
        if (matchRoadworks?.params.slug) {
            const filtersFromParams = getFiltersFromSearchParams(getParams)
            const existingFiltersFromParams = Object.keys(filtersFromParams || {}).length
            if (existingFiltersFromParams) {
                setFilters({
                    ...roadworkState.filters,
                    ...filtersFromParams,
                })
                setImageCollectionYearFromFilters(filtersFromParams)
            } else if (roadworkState.filters && Object.keys(roadworkState.filters).length) {
                const paramsFilters = getSearchParamsFromFilters(roadworkState.filters)
                for (const [key, value] of Object.entries(paramsFilters)) {
                    setParam(key, value)
                }
                setImageCollectionYearFromFilters(filtersFromParams)
            } else {
                setImageCollectionYearFromFilters({})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        matchRoadworks?.params.slug,
        getParams,
        setParam,
        setFilters,
        setImageCollectionYearFromFilters])

    useEffect(() => {
        const searchParamsSelectedRoadwork = getParam(SELECTED_ROADWORK_URL_PARAM)

        if (roadworkState.selectedRoadwork && !searchParamsSelectedRoadwork) {
            setParam(SELECTED_ROADWORK_URL_PARAM, roadworkState.selectedRoadwork)
        }
    }, [getParam, setParam, roadworkState.selectedRoadwork])

    useEffect(() => {
        if (matchRoadworks?.params.slug && techniqueCodes?.length) {
            const existingTechniques = roadworkState.filters.techniques.filter(technique => techniqueCodes.map(t => t.code).includes(technique))
            if (existingTechniques.length !== roadworkState.filters.techniques.length) {
                setFilters({
                    ...roadworkState.filters,
                    techniques: existingTechniques,
                })
            }
        }
    }, [matchRoadworks?.params.slug, roadworkState.filters, techniqueCodes, setFilters])

    const stateValue = useMemo(() => {
        return {
            apiFilters,
            currency: project?.currency,
            filters: roadworkState.filters,
            isErrorRoadworks,
            isLoadingRoadworks,
            project: project,
            selectedTechnique: roadworkState.selectedTechnique,
            selectedRoadwork: roadworkState.selectedRoadwork,
            surveys,
            selectedSegmentUuid: roadworkState.selectedSegmentUuid,
        }
    }, [apiFilters,
        isErrorRoadworks,
        isLoadingRoadworks,
        project,
        surveys,
        roadworkState.filters,
        roadworkState.selectedTechnique,
        roadworkState.selectedRoadwork,
        roadworkState.selectedSegmentUuid])

    const dispatchValue = useMemo(() => {
        return {
            getSurveyForWorks,
            applyFilters,
            setFilters,
            setSelectedSegment,
            setSelectedTechnique,
            setSelectedRoadwork,
        }
    }, [getSurveyForWorks,
        applyFilters,
        setFilters,
        setSelectedSegment,
        setSelectedTechnique,
        setSelectedRoadwork])

    return (
        <RoadworksStateContext.Provider value={stateValue}>
            <RoadworksDispatchContext.Provider value={dispatchValue}>
                {children}
            </RoadworksDispatchContext.Provider>
        </RoadworksStateContext.Provider>
    )
}

RoadworksContextProvider.propTypes = {
    children: PropTypes.node,
}
