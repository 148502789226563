import { queryKeyFactory } from "@l2r-front/l2r-query"

export const MIRANDA_GRADINGS_ROOT_QUERY_KEY = "mirandaGradings"

export const mirandaQueryKeys = queryKeyFactory(MIRANDA_GRADINGS_ROOT_QUERY_KEY, {
    layer: (filters = {}) => [MIRANDA_GRADINGS_ROOT_QUERY_KEY, "layer", filters] as const,
    listRelevants: (project) => [MIRANDA_GRADINGS_ROOT_QUERY_KEY, "list-relevants", project] as const,
    project: (project) => [MIRANDA_GRADINGS_ROOT_QUERY_KEY, "project", project] as const,
    stats: (project, filters = {}) => [MIRANDA_GRADINGS_ROOT_QUERY_KEY, "stats", project, filters] as const,
})
