import { Menu as UiMenu, MenuItem, styled } from "@l2r-front/l2r-ui"

export const Menu = styled(UiMenu)(({ theme }) => ({
    "& .MuiMenu-paper": {
        overflow: "visible",
        marginTop: theme.spacing(1),
        borderRadius: theme.spacing(3),
    },
}))

export const LogoutMenuItem = styled(MenuItem)(({ theme }) => ({
    marginTop: theme.spacing(2),
}))
