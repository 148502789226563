import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { ColoredBadge } from "@l2r-front/l2r-ui"

import { GradeBadge } from "../../../../common/components/GradeBadge"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { networkGradingsQueryKeys } from "../../hooks/queries/networkGradings/queryKeys"
import { useWeightedAverageGrades } from "../../hooks/queries/networkGradings/useWeightedAverageGrades"

import * as Styled from "./RoadNotationGrade.styled"

export function RoadNotationGrade(props) {

    const {
        notationLayer,
        notationKey,
    } = props

    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { road } = useParams()
    const layer = notationLayer || selectedNetworkYearlyStatus?.layer
    const queryKeyParams = { road, ...(!!layer && { layer }) }
    const { data: roadSegments, isLoading, isError } = useGeoServerFeaturesList(
        networkGradingsQueryKeys.list(queryKeyParams),
        {
            layer: layer,
            linear_location_road: road,
        },
        {
            enabled: !!road && !!layer,
        })
    const averageGrades = useWeightedAverageGrades(roadSegments)
    const grade = useMemo(() => {
        if (!averageGrades) {
            return null
        }
        return averageGrades[notationKey]
    }, [notationKey, averageGrades])

    if (layer && (isLoading || (!roadSegments && !isError))) {
        return <Styled.Skeleton />
    }

    if (isError) {
        return <ColoredBadge
            backgroundColor="surfaces/surface-error"
            text={t(I18N_NAMESPACE, "containers.weightedAverageNotationGrade.error")}
            textVariant="H3"
        />
    }

    return <GradeBadge grade={grade} />
}