import { useCallback, useMemo } from "react"

import lodash from "lodash"

import {
    Layer,
    useMapStateContext,
    MapStyles,
    getInterpolatedLineLayerWidth,
    getInterpolatedSelectableLineLayerWidth,
    getSelectableLineSortKey,
    NOTATION_SOURCE,
    NOTATION_LAYER,
    NOTATION_LAYER_CLICK,
    useOrderingLayers,
    getLineLayerConfig,
    SELECTED_LINE_LAYER_SIZE,
} from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"

import { getMirandaLayerStyle } from "../../constants/getNotationConfig"

import { MirandaNotationSource } from "../MirandaNotationSource"

export function MirandaNotationLayer(props) {
    const { onClickOnFeature } = props

    const { selectedLinearLocations } = useNetworksStateContext()
    const { currentMapStyle } = useMapStateContext()
    const theme = useTheme()

    const notationLayerConfig = useMemo(() => {
        const currentType = "grade"
        const referentialColor = currentMapStyle === MapStyles.plan ? theme.palette.map.darkReferential : theme.palette.map.lightReferential
        const selectedRoad = selectedLinearLocations?.[0].road

        return lodash.merge(
            getMirandaLayerStyle(currentType, referentialColor, theme, selectedRoad),
            {
                "paint": {
                    "line-width": getInterpolatedSelectableLineLayerWidth(selectedRoad),
                },
                "layout": {
                    "line-sort-key": getSelectableLineSortKey(selectedRoad),
                },
            },
        )
    }, [selectedLinearLocations, currentMapStyle, theme])

    const notationLayerClickConfig = useMemo(() => {
        return getLineLayerConfig({
            "paint": {
                "line-color": "transparent",
                "line-width": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE),
            },
        })
    }, [])

    useOrderingLayers([NOTATION_LAYER, NOTATION_LAYER_CLICK])

    const handleLayerClick = useCallback(async (event) => {
        const feature = event?.feature

        if (!feature) {
            return
        } else {
            if (onClickOnFeature) {
                onClickOnFeature(feature)
            }
        }
    }, [onClickOnFeature])

    return (
        <MirandaNotationSource id={NOTATION_SOURCE} >
            <Layer
                {...notationLayerConfig}
                id={NOTATION_LAYER}
            />
            <Layer
                {...notationLayerClickConfig}
                id={NOTATION_LAYER_CLICK}
                onClick={handleLayerClick}
            />
        </MirandaNotationSource>
    )
}

MirandaNotationLayer.propTypes = {
    isSegmentView: PropTypes.bool,
}

MirandaNotationLayer.propTypes = {
    onClickOnFeature: PropTypes.func,
}

