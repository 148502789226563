import { useMemo } from "react"

import { Avatar as MuiAvatar } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./Avatar.styled"

export const Avatar = (props) => {
    const {
        alt,
        children,
        ...avatarProps
    } = props

    const initials = useMemo(() => {
        const altSplitted = alt.split(" ")
        return altSplitted.reduce((acc, current) => {
            if (acc === "") {
                return current.charAt(0).toUpperCase() + current.charAt(1).toLowerCase()
            }
            return acc[0] + current.charAt(0).toUpperCase()
        }, "")
    }, [alt])

    return <Styled.Avatar {...avatarProps} withBorder={!!avatarProps?.src}>
        {children || initials}
    </Styled.Avatar>
}

Avatar.propTypes = {
    ...MuiAvatar.propTypes,
    alt: PropTypes.string.isRequired,
}