import { PropTypes } from "@l2r-front/l2r-proptypes"
import { AppBar as UiAppBar } from "@l2r-front/l2r-ui"

import * as Styled from "./AppBar.styled"

export function AppBar(props) {
    const {
        children,
        moduleTabs,
        ...uiAppBarProps
    } = props

    return (
        <Styled.AppBar elevation={29} {...uiAppBarProps} moduleTabs={!!moduleTabs}>
            <Styled.Toolbar disableGutters>
                {children}
            </Styled.Toolbar>
            {moduleTabs}
        </Styled.AppBar>
    )
}

AppBar.propTypes = {
    moduleTabs: PropTypes.node,
    ...UiAppBar.propTypes,
}