import { useCallback, useMemo } from "react"

import { AxisLeft, AxisBottom } from "@visx/axis"
import { RectClipPath } from "@visx/clip-path"
import { Grid } from "@visx/grid"
import { Group } from "@visx/group"
import { scaleLinear, scaleTime } from "@visx/scale"
import { LinePath } from "@visx/shape"
import { applyMatrixToPoint, Zoom } from "@visx/zoom"
import { addDays, addMonths, subMonths, differenceInDays } from "date-fns"
import { useMeasure } from "react-use"

import { useTheme } from "@l2r-front/l2r-ui"
import { rescaleChart } from "@l2r-front/l2r-utils"

import { GradeTickLabel } from "../../components/GradeTickLabel"
import { notationLegend, getLegendIndexFromGrade, getLegendItemForGrade } from "../../constants/getNotationConfig"
import { GRADE_TICK_LABEL_HEIGHT, DATE_COLUMN_WIDTH } from "../../constants/layout"

import { GradeAnnotation } from "../GradeAnnotation"

export function NotationTrendingKpi(props) {
    const {
        values,
        lastSurveyIndex,
    } = props

    const [ref, { width, height }] = useMeasure()

    const theme = useTheme()

    const margin = { top: 10, right: 0, bottom: 40, left: 40 }
    const xMax = width - margin.left - margin.right
    const yMax = height - margin.top - margin.bottom

    const getDate = useCallback((d) => new Date(d.date), [])

    const valuesWithThresholds = useMemo(() => {
        const vals = []

        if (values?.length) {
            for (let i = 0; i < values.length - 1; i++) {
                const val = values[i]
                const nextVal = values[i + 1]
                vals.push(val)
                const valLegendIndex = getLegendIndexFromGrade(val.grade)
                const nextValLegendIndex = getLegendIndexFromGrade(nextVal.grade)
                for (let j = 0; j < Math.abs(nextValLegendIndex - valLegendIndex); j++) {
                    const thresholdGrade = (valLegendIndex < nextValLegendIndex) ? notationLegend[valLegendIndex + j].maxValue : notationLegend[valLegendIndex - j].minValue
                    const gradeRatio = (thresholdGrade - val.grade) / (nextVal.grade - val.grade)
                    const daysCountBetweenVals = differenceInDays(getDate(nextVal), getDate(val))
                    const thresholdDate = addDays(getDate(val), daysCountBetweenVals * gradeRatio)
                    vals.push({
                        date: thresholdDate.toISOString(),
                        grade: thresholdGrade,
                    })
                }
            }
            vals.push(values[values.length - 1])
        }

        return vals
    }, [values, getDate])

    const minYear = useMemo(() => {
        return valuesWithThresholds?.length ? Math.min(...valuesWithThresholds.map(v => getDate(v).getFullYear())) : new Date().getFullYear()
    }, [valuesWithThresholds, getDate])

    const maxYear = useMemo(() => {
        return valuesWithThresholds?.length ? Math.max(...valuesWithThresholds.map(v => getDate(v).getFullYear())) + 1 : new Date().getFullYear() + 1
    }, [valuesWithThresholds, getDate])

    const dateTickValues = useMemo(() => {
        return Array.from({ length: maxYear - minYear + 1 }, (_, i) => getDate({ date: new Date(i + minYear, 0, 1) }))
    }, [getDate, minYear, maxYear])

    const minDate = useMemo(() => {
        return valuesWithThresholds?.length ? new Date(Math.min(...valuesWithThresholds.map(v => subMonths(getDate(v), 6)))) : minYear
    }, [valuesWithThresholds, getDate, minYear])

    const maxDate = useMemo(() => {
        return valuesWithThresholds?.length ? new Date(Math.max(...valuesWithThresholds.map(v => addMonths(getDate(v), 6)))) : maxYear
    }, [valuesWithThresholds, getDate, maxYear])

    const dateScale = scaleTime({
        domain: [minDate, maxDate],
    })
    dateScale.range([0, xMax])

    const gradeScale = scaleLinear({
        domain: [
            0,
            10,
        ],
        nice: true,
    })
    gradeScale.range([yMax, 0])

    const initialZoom = useMemo(() => ({
        scaleX: Math.max(1, DATE_COLUMN_WIDTH * (dateTickValues.length) / xMax),
        scaleY: 1,
        translateX: 0,
        translateY: 0,
        skewX: 0,
        skewY: 0,
    }), [dateTickValues, xMax])

    function constrainZoom(transformMatrix, prevTransformMatrix) {
        const min = applyMatrixToPoint(transformMatrix, { x: 0, y: 0 })
        const max = applyMatrixToPoint(transformMatrix, { x: width, y: 0 })
        if (max.x < width) {
            return prevTransformMatrix
        }
        if (min.x > 0) {
            return prevTransformMatrix
        }
        return transformMatrix
    }

    return (
        <div ref={ref} style={{ position: "relative", width: "100%", height: "100%" }}>
            {xMax > 0 && <Zoom
                height={height}
                initialTransformMatrix={initialZoom}
                width={width}
                scaleXMin={1}
                scaleYMin={1}
                scaleYMax={1}
                constrain={constrainZoom}
                wheelDelta={() => {
                    return {
                        scaleX: 1,
                        scaleY: 1,
                    }
                }}
            >
                {(zoom) => {
                    const dateZoomScale = rescaleChart(dateScale, zoom)
                    const dateTickValuesInZoom = dateTickValues.filter(d => d >= dateZoomScale.domain()[0] && d <= dateZoomScale.domain()[1])
                    return (
                        <svg
                            width={width}
                            height={height}
                            style={{ cursor: zoom.isDragging ? "grabbing" : "grab", touchAction: "none" }}
                            ref={zoom.containerRef}>
                            <RectClipPath id="zoom-clip" x={margin.left} y={0} width={xMax} height={height} />
                            <rect
                                x={0}
                                y={0}
                                width={width}
                                height={height}
                                fill="transparent"
                                rx={14}
                                onMouseUp={(e) => {
                                    e.stopPropagation()
                                    zoom?.dragEnd(e)
                                }}
                                onMouseDown={(e) => {
                                    e.stopPropagation()
                                    zoom?.dragStart(e)
                                }}
                                onTouchEnd={(e) => {
                                    e.stopPropagation()
                                    zoom?.dragEnd(e)
                                }}
                                onTouchMove={zoom?.dragMove}
                                onTouchStart={(e) => {
                                    e.stopPropagation()
                                    zoom?.dragStart(e)
                                }} />
                            <Group left={margin.left} top={margin.top} width={xMax} height={yMax}>
                                <Grid
                                    xScale={dateZoomScale}
                                    yScale={gradeScale}
                                    rowTickValues={notationLegend.map(legendItem => legendItem.minValue)}
                                    columnTickValues={dateTickValuesInZoom}
                                    width={xMax}
                                    height={yMax}
                                    stroke="black"
                                    strokeWidth={1.11}
                                    strokeOpacity={0.1}
                                    clipPath="zoom-clip"
                                />
                                <AxisBottom
                                    top={yMax}
                                    width={xMax}
                                    scale={dateZoomScale}
                                    stroke="black"
                                    strokeWidth={1.5}
                                    tickValues={dateTickValuesInZoom}
                                    tickStroke="black"
                                    tickLabelProps={() => ({
                                        fontFamily: "Poppins",
                                        fontSize: "12px",
                                        fontWeight: 600,
                                        textAnchor: "middle",
                                    })}
                                    clipPath="zoom-clip"
                                />
                                <AxisLeft
                                    hideAxisLine
                                    hideTicks
                                    scale={gradeScale}
                                    strokeWidth={1.5}
                                    tickValues={notationLegend.map(legendItem => legendItem.minValue)}
                                    tickLabelProps={(tickLabel) => {
                                        const legendItem = getLegendItemForGrade(notationLegend, tickLabel, theme)
                                        return {
                                            backgroundColor: theme.palette[legendItem.color].main,
                                            label: legendItem.shortLabel,
                                            textColor: legendItem.textColor,
                                        }
                                    }}
                                    tickTransform={`translate(-20, ${-yMax / notationLegend.length / 2 - GRADE_TICK_LABEL_HEIGHT / 2})`}
                                    tickComponent={GradeTickLabel}
                                />
                                {values?.length &&
                                    <svg id="notation-trending-kpi-data-trace" clipPath="zoom-clip">
                                        {
                                            valuesWithThresholds.slice(0, valuesWithThresholds.length - 1).map((v, index) => {
                                                const nextValue = valuesWithThresholds[index + 1]
                                                const middleGrade = (v.grade + nextValue.grade) / 2
                                                const color = theme.palette[getLegendItemForGrade(notationLegend, middleGrade, theme).color].main
                                                return (
                                                    <LinePath
                                                        key={`linepath-${index}`}
                                                        stroke={color}
                                                        strokeWidth={3}
                                                        strokeDasharray={lastSurveyIndex >= 0 && getDate(nextValue) <= getDate(values[lastSurveyIndex]) ? [] : [5, 5]}
                                                        data={[v, nextValue]}
                                                        x={(v) => dateZoomScale(getDate(v)) ?? 0}
                                                        y={(v) => gradeScale(v.grade) ?? 0}
                                                        clipPath="zoom-clip"
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            values.map((v, index) => (
                                                <circle
                                                    key={`grade-marker-${index}`}
                                                    r={3}
                                                    cx={dateZoomScale(getDate(v))}
                                                    cy={gradeScale(v.grade)}
                                                    stroke="black"
                                                    fill="transparent"
                                                />
                                            ))
                                        }
                                        {
                                            values.map((v, index) => {
                                                const legendItemForGrade = getLegendItemForGrade(notationLegend, v.grade, theme)
                                                return <GradeAnnotation
                                                    key={`grade-annotation-${index}`}
                                                    x={dateZoomScale(getDate(v))}
                                                    y={gradeScale(v.grade)}
                                                    backgroundColor={theme.palette[legendItemForGrade.color].main}
                                                    grade={v.grade}
                                                    textColor={legendItemForGrade.textColor}
                                                />
                                            })
                                        }
                                    </svg>
                                }
                            </Group>
                        </svg>
                    )
                }}
            </Zoom>
            }
        </div>
    )
}