import { useCallback, useMemo } from "react"

import lodash from "lodash"

import {
    Layer,
    useMapStateContext,
    MapStyles,
    getInterpolatedLineLayerWidth,
    getInterpolatedSelectableLineLayerWidth,
    getSelectableLineSortKey,
    NOTATION_SOURCE,
    NOTATION_LAYER,
    NOTATION_LAYER_CLICK,
    useOrderingLayers,
    getLineLayerConfig,
    SELECTED_LINE_LAYER_SIZE,
} from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"

import { NETWORK_GRADING_PREFIX_PARAM } from "../../../../common/constants/urlParams"
import { getNotationLayerStyle } from "../../constants/getNotationConfig"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"

import { NetworkGradingSource } from "../NetworkGradingSource"

export function NotationLayer(props) {
    const { onClickOnFeature } = props

    const { selectedNetworkAssesmentType } = useRoadwayStateContext()
    const { selectedLinearLocations } = useNetworksStateContext()
    const { currentMapStyle } = useMapStateContext()
    const theme = useTheme()

    const notationLayerConfig = useMemo(() => {
        const currentType = selectedNetworkAssesmentType?.replace(NETWORK_GRADING_PREFIX_PARAM, "")
        const referentialColor = currentMapStyle === MapStyles.plan ? theme.palette.map.darkReferential : theme.palette.map.lightReferential
        const selectedRoad = selectedLinearLocations?.[0].road

        return lodash.merge(
            getNotationLayerStyle(currentType, referentialColor, theme, selectedRoad),
            {
                "paint": {
                    "line-width": getInterpolatedSelectableLineLayerWidth(selectedRoad),
                },
                "layout": {
                    "line-sort-key": getSelectableLineSortKey(selectedRoad),
                },
            },
        )
    }, [selectedNetworkAssesmentType, selectedLinearLocations,
        currentMapStyle, theme])

    const notationLayerClickConfig = useMemo(() => {
        return getLineLayerConfig({
            "paint": {
                "line-color": "transparent",
                "line-width": getInterpolatedLineLayerWidth(SELECTED_LINE_LAYER_SIZE),
            },
        })
    }, [])

    useOrderingLayers([NOTATION_LAYER, NOTATION_LAYER_CLICK])

    const handleLayerClick = useCallback(async (event) => {
        const feature = event?.feature
        if (!feature) {
            return
        } else {
            if (onClickOnFeature) {
                onClickOnFeature(feature)
            }
        }
    }, [onClickOnFeature])

    return (
        <NetworkGradingSource id={NOTATION_SOURCE} >
            <Layer
                {...notationLayerConfig}
                id={NOTATION_LAYER}
            />
            <Layer
                {...notationLayerClickConfig}
                id={NOTATION_LAYER_CLICK}
                onClick={handleLayerClick}
            />
        </NetworkGradingSource>
    )
}

NotationLayer.propTypes = {
    isSegmentView: PropTypes.bool,
}

NotationLayer.propTypes = {
    onClickOnFeature: PropTypes.func,
}

