import {
    ToggleButton,
    ToggleButtonGroup,
    styled,
} from "@l2r-front/l2r-ui"

const buttonStyle = (theme) => ({
    borderRadius: theme.spacing(50),
    border: `1px solid ${theme.palette.primary.contrast}`,

    "&.Mui-disabled": {
        color: theme.palette["components/app-bar/button/disabled/contrast"].main,
        backgroundColor: theme.palette["components/app-bar/button/disabled/main"].main,
        border: `1px dashed ${theme.palette["components/app-bar/button/disabled/contrast"].main}`,
    },
})

export const Container = styled(ToggleButtonGroup)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(6),
    alignItems: "flex-start",

    "& .MuiToggleButtonGroup-firstButton": buttonStyle(theme),
    "& .MuiToggleButtonGroup-middleButton": buttonStyle(theme),
    "& .MuiToggleButtonGroup-lastButton": buttonStyle(theme),
}))

export const AppButton = styled(ToggleButton)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    gap: theme.spacing(1),
    height: 40,

    "&.Mui-selected": {
        color: theme.palette.primary.contrastText,
        border: `1px solid ${theme.palette["colors/vert logiroad/600"].main}`,
        backgroundColor: theme.palette["colors/vert logiroad/600"].main,

        "&:hover": {
            border: `1px solid ${theme.palette["colors/vert logiroad/600"].main}`,
            backgroundColor: theme.palette["colors/vert logiroad/600"].main,
        },
    },

    "&:hover": {
        backgroundColor: theme.palette["colors/vert logiroad/600"].main,
        border: `1px solid ${theme.palette["colors/vert logiroad/600"].main}`,
    },

    ...theme.typography.Regular,
}))