import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import { RoadworksStats } from "../../components/RoadworksStats"
import { useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { useRoadworksStats } from "../../hooks/queries/useRoadworksStats"
import { formatNumber } from "../../utils/formatting"

import * as Styled from "./RoadworkStat.styled"

export function RoadworkCost(props) {
    const {
        title,
        isDisplayTotalCost,
    } = props
    const { apiFilters, currency } = useRoadworksStateContext()
    const newApiFilter = { ...apiFilters, status: [] }
    const { road } = useParams()
    const { data: stats } = useRoadworksStats({ ...newApiFilter, linear_location_road: road })

    const totalCost = useMemo(() => {
        if (!stats?.cost) {
            return null
        }
        const cost = Object.values(stats?.cost).map(Number).reduce((sum, value) => sum + value, 0)
        return cost
    }, [stats])

    const filteredCost = useMemo(() => {
        if (!stats?.cost) {
            return null
        }
        const statusFilter = apiFilters?.status?.length
            ? apiFilters.status
            : apiFilters?.status__in?.length
                ? apiFilters.status__in
                : []
        if (statusFilter.length === 0) {
            return Object.values(stats.cost).reduce((sum, value) => sum + Number(value), 0)
        }
        return statusFilter.reduce((sum, key) => sum + (Number(stats.cost[key]) || 0), 0)
    }, [apiFilters.status, apiFilters.status__in, stats])

    const displayedCost = useMemo(() => isDisplayTotalCost ? totalCost : filteredCost, [isDisplayTotalCost, totalCost, filteredCost])

    return <RoadworksStats title={title}>
        <Styled.Stat id="roadworks-total-cost">{formatNumber(displayedCost)} {currency?.symbol}</Styled.Stat>
    </RoadworksStats>
}

RoadworkCost.propTypes = {
    title: PropTypes.string,
    isDisplayTotalCost: PropTypes.bool,
}

RoadworkCost.defaultProps = {
    isDisplayTotalCost: false,
}