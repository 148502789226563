import { useMemo } from "react"

import { useLocation, useNavigate, matchRoutes } from "react-router-dom"

import { useUrlParams } from "../utils/useUrlParams"

export const useHandleBack = (getBreadcrumbPaths) => {

    const navigate = useNavigate()
    const location = useLocation()
    const { getParams } = useUrlParams()

    const breadcrumbPaths = useMemo(() => {
        const params = getParams()
        if (getBreadcrumbPaths) {
            return getBreadcrumbPaths(params, location)
        }
        return []
    }, [getParams, getBreadcrumbPaths, location])

    const matches = matchRoutes(breadcrumbPaths, location)

    const newPath = useMemo(() => {
        if (matches) {
            const newRoutes = matches[0].route.newPath
            const [parts, search] = newRoutes.split("?")
            const routeParts = parts.split("/")
            const matchedRoutesParts = routeParts.map(rp => {
                if (rp.startsWith(":")) {
                    const matchString = rp.slice(1)
                    return matches[0].params[matchString]
                }

                return rp
            })
            return `${matchedRoutesParts.join("/")}?${search ?? ""}`
        }
        return null
    }, [matches])

    const handleBack = newPath ? () => navigate(newPath) : null

    return ({ handleBack })
}

