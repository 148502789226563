import { useMemo } from "react"

import { useTranslation, useDateFormatter } from "@l2r-front/l2r-i18n"
import { VibrationIcon } from "@l2r-front/l2r-icons"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { RoadAssesmentList } from "../../../../common/components/RoadAssesmentList"
import { API_MODULE_MIRANDA, API_MODULE_ROADWAY } from "../../../../common/constants/appRoad"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { MIRANDA_GRADE_TYPE, NETWORK_GRADING_PREFIX_PARAM, NOTATION_GRADE_TYPE } from "../../../../common/constants/urlParams"
import { networkGradeTypesConfig } from "../../constants/roadInfoConfig"
import { useRoadwayDispatchContext, useRoadwayStateContext } from "../../contexts/RoadwayContext"

import { MirandaGrade } from "../MirandaGrade"
import { NetworkDamagesList } from "../NetworkDamagesList/NetworkDamagesList"
import { RoadInfoItem } from "../RoadInfoItem"
import { WeightedAverageNotationGrade } from "../WeightedAverageNotationGrade/WeightedAverageNotationGrade"

import * as Styled from "./RoadwaySidebarContent.styled"

export function RoadwaySidebarContent(props) {

    const {
        className,
    } = props

    const { t } = useTranslation([I18N_NAMESPACE])
    const dateFormatter = useDateFormatter()
    const { selectedNetwork } = useNetworksStateContext()

    const {
        availableNotationType,
        selectedNetworkYearlyStatus,
        selectedNetworkAssesmentType,
    } = useRoadwayStateContext()

    const { setSelectedNetworkAssesmentType, getSurveyForNetworkGrading } = useRoadwayDispatchContext()
    const isMobile = useIsMobileDevice()

    const notationsItems = useMemo(() => {
        return Object.keys(networkGradeTypesConfig).map(notationKey => {
            return (
                <RoadInfoItem
                    disabled={!availableNotationType?.includes(NOTATION_GRADE_TYPE)}
                    id={`notation-list-item-${notationKey}`}
                    key={notationKey}
                    label={t(I18N_NAMESPACE, networkGradeTypesConfig[notationKey].label)}
                    icon={networkGradeTypesConfig[notationKey].icon}
                    onClick={() => setSelectedNetworkAssesmentType(`${NETWORK_GRADING_PREFIX_PARAM}${notationKey}`)}
                    tooltipText={t(I18N_NAMESPACE, networkGradeTypesConfig[notationKey].tooltipText)}
                    selected={selectedNetworkAssesmentType === `${NETWORK_GRADING_PREFIX_PARAM}${notationKey}`}
                >
                    {selectedNetworkYearlyStatus && <WeightedAverageNotationGrade id={`notation-${notationKey}`} year={selectedNetworkYearlyStatus?.year} notationKey={notationKey} />}
                </RoadInfoItem>
            )
        })
    }, [availableNotationType, selectedNetworkAssesmentType, selectedNetworkYearlyStatus, setSelectedNetworkAssesmentType, t])

    const description = useMemo(() => {
        const survey = getSurveyForNetworkGrading(selectedNetworkYearlyStatus)
        if (selectedNetwork.modules.includes(API_MODULE_MIRANDA) && !selectedNetwork.modules.includes(API_MODULE_ROADWAY)) {
            return null
        } else if (!survey) {
            return t(I18N_NAMESPACE, "containers.roadwayDetailSidebar.noNotationDescription", {
                year: selectedNetworkYearlyStatus?.year,
            })
        }
        const date = dateFormatter(new Date(survey.date), "dd MMMM yyyy")
        if (selectedNetworkYearlyStatus.isSimulated) {
            return t(I18N_NAMESPACE, "containers.roadwaySidebar.notations.simulationDescription", {
                year: selectedNetworkYearlyStatus?.year,
                date,
            })
        } else {
            return t(I18N_NAMESPACE, "containers.roadwaySidebar.notations.surveyDescription", {
                year: selectedNetworkYearlyStatus?.year,
                date,
            })
        }
    }, [selectedNetwork, selectedNetworkYearlyStatus, getSurveyForNetworkGrading, t, dateFormatter])

    return (
        <Styled.Container className={className} id="sidebar-content-container">
            <Styled.HeaderWrapper isMobile={isMobile}>
                <Styled.YearSelector />
                {description && <Styled.NetworkGradingSelectorDescription id="roadState-description" variant="Small">
                    {description}
                </Styled.NetworkGradingSelectorDescription>}
            </Styled.HeaderWrapper>
            {selectedNetwork.modules.includes(API_MODULE_ROADWAY) && <RoadAssesmentList listKey="notations" title={t(I18N_NAMESPACE, "containers.roadwaySidebar.notations.title")}>
                {notationsItems}
            </RoadAssesmentList>}
            {selectedNetwork.modules.includes(API_MODULE_MIRANDA) && <RoadAssesmentList listKey="miranda-notations" title={t(I18N_NAMESPACE, "containers.roadwaySidebar.mirandaNotations.title")}>
                <RoadInfoItem
                    disabled={!availableNotationType?.includes(MIRANDA_GRADE_TYPE)}
                    id={"notation-list-item-miranda"}
                    key={MIRANDA_GRADE_TYPE}
                    label={t(I18N_NAMESPACE, "containers.roadwaySidebar.mirandaNotations.label")}
                    icon={VibrationIcon}
                    onClick={() => setSelectedNetworkAssesmentType(MIRANDA_GRADE_TYPE)}
                    tooltipText={t(I18N_NAMESPACE, "containers.roadwaySidebar.mirandaNotations.tooltip")}
                    selected={selectedNetworkAssesmentType === MIRANDA_GRADE_TYPE}
                >
                    <MirandaGrade />
                </RoadInfoItem>
            </RoadAssesmentList>}
            {selectedNetwork.modules.includes(API_MODULE_ROADWAY) && <NetworkDamagesList />}
        </Styled.Container>
    )
}

RoadwaySidebarContent.propTypes = {
    className: PropTypes.string,
}