import { PropTypes } from "@l2r-front/l2r-proptypes"
import { ColoredBadge, useTheme } from "@l2r-front/l2r-ui"

import { getLegendItemForGrade, notationLegend } from "../../../modules/roadway/constants/getNotationConfig"
import { getDisplayedFigures } from "../../utils/getDisplayedFigures"

export function GradeBadge(props) {

    const {
        id,
        className,
        figuresCount,
        grade,
        legend,
    } = props

    const theme = useTheme()

    const displayedGrade = getDisplayedFigures(grade, figuresCount)

    if (!grade && grade !== 0) {
        return <ColoredBadge
            id={id}
            backgroundColor="objects/object-grey"
            text="-"
        />
    }

    const legendItemForGrade = getLegendItemForGrade(legend, grade, theme)
    return <ColoredBadge
        id={id}
        backgroundColor={theme.palette[legendItemForGrade.color].main}
        className={className}
        text={displayedGrade}
        textColor={legendItemForGrade.textColor}
    />
}

GradeBadge.defaultProps = {
    figuresCount: 2,
    legend: notationLegend,
}

GradeBadge.propTypes = {
    className: PropTypes.string,
    grade: PropTypes.number,
    figuresCount: PropTypes.number,
    legend: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        label: PropTypes.string,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
    })),
}