import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { formatMetersToDistance } from "@l2r-front/l2r-utils"

import { RoadworksStats } from "../../components/RoadworksStats"
import { useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { useRoadworksStats } from "../../hooks/queries/useRoadworksStats"

import * as Styled from "./RoadworkStat.styled"

export function RoadworkLinearLengthUnique(props) {
    const {
        title,
    } = props
    const { apiFilters } = useRoadworksStateContext()
    const { road } = useParams()
    const { data: stats } = useRoadworksStats({ ...apiFilters, linear_location_road: road })

    const linearLength = useMemo(() => {
        if (!stats?.lengthUnique) {
            return null
        }
        const statusFilter = apiFilters?.status?.length
            ? apiFilters.status
            : apiFilters?.status__in?.length
                ? apiFilters.status__in
                : Object.keys(stats.lengthUnique).map(Number)

        return statusFilter.reduce((sum, key) => sum + (stats.lengthUnique[key] || 0), 0)
    }, [apiFilters.status, apiFilters.status__in, stats])


    return <RoadworksStats title={title}>
        <Styled.Stat id="roadworks-unique-length">{formatMetersToDistance(linearLength)}</Styled.Stat>
    </RoadworksStats>
}

RoadworkLinearLengthUnique.propTypes = {
    title: PropTypes.string,
}