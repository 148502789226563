import { useCallback, useEffect, useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { AddIcon } from "@l2r-front/l2r-icons"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"
import { handleRoundNumber } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworksList } from "../../components/RoadworksList"
import {
    TechniqueItemCardError,
    TechniqueItemCardSkeleton,
} from "../../components/TechniqueItemCard"
import { STATUS_DONE } from "../../constants/status"
import {
    useRoadworksStateContext,
    useRoadworksDispatchContext,
} from "../../contexts/RoadworksContext"
import { useRoadworks } from "../../hooks/queries/useRoadworks"
import { formatNumber } from "../../utils/formatting"

import { RoadworkCard } from "../RoadworkCard"
import { RoadworkLinearLengthUnique, RoadworkCost } from "../RoadworkStats"

import * as Styled from "./RoadRoadworksList.styled"

export function RoadRoadworksList(props) {

    const {
        className,
        openDrawModal,
    } = props

    const [sortedRoadworksUuids, setSortedRoadworksUuids] = useState([])
    const readOnly = useRoleIsReadOnly()
    const { road } = useParams()
    const { apiFilters, selectedRoadwork } = useRoadworksStateContext()
    const { setSelectedRoadwork } = useRoadworksDispatchContext()
    const filtersWithoutStatus = {
        ...apiFilters,
        status: null,
        status__in: [],
    }
    const { data: roadworks, isError, isLoading } = useRoadworks(filtersWithoutStatus, road)
    const { t } = useTranslation(I18N_NAMESPACE)
    const { currency } = useRoadworksStateContext()

    useEffect(() => {
        if (roadworks) {
            if (!areSameRoadworks(roadworks, sortedRoadworksUuids)) {
                const sortedRoadworks = sortRoadworks(roadworks)
                const sortedRoadworksIds = sortedRoadworks.map(roadwork => roadwork.uuid)
                setSortedRoadworksUuids(sortedRoadworksIds)
            }
        }
    }, [roadworks, sortedRoadworksUuids])

    const displayedRoadworks = useMemo(() => {
        if (roadworks?.length && areSameRoadworks(roadworks, sortedRoadworksUuids)) {
            return forceSortRoadworks(roadworks, sortedRoadworksUuids)
        } else if (roadworks?.length) {
            return sortRoadworks(roadworks)
        }
        return null
    }, [roadworks, sortedRoadworksUuids])

    useEffect(() => {
        if (!isLoading && !isError && currency?.symbol && selectedRoadwork && roadworks) {
            if (!roadworks?.length || !roadworks.map(roadwork => roadwork.uuid).includes(selectedRoadwork)) {
                setSelectedRoadwork(null)
            }
        }
    }, [roadworks, selectedRoadwork, currency?.symbol, isLoading, isError, setSelectedRoadwork])

    const defaultRoadworksArray = [...Array(4).keys()]
    const doneStatusCost = useMemo(() =>
        (roadworks ?? []).reduce((sum, { status, technique }) =>
            status === STATUS_DONE ? sum + handleRoundNumber(technique.cost, 0) : sum,
        0),
    [roadworks])

    const Header = useCallback(() => {
        return <Styled.Header>
            <Styled.TextWrapper>
                <Typography variant="H2">{t(I18N_NAMESPACE, "containers.roadRoadworksList.title")}</Typography>
                <Styled.TotalCostContainer>
                    <RoadworkCost title={t(I18N_NAMESPACE, "containers.roadworksList.totalCost")} isDisplayTotalCost={true}/>
                    {roadworks?.length === 0 ? <></> :
                        <Styled.DoneTag>
                            <Typography variant="Very small">
                                {`${t(I18N_NAMESPACE, "containers.roadworksList.doneTag")} ${formatNumber(doneStatusCost)} ${currency?.symbol}`}
                            </Typography>
                            <Styled.CheckCircleIcon color="objects/object-primary" />
                        </Styled.DoneTag>}
                </Styled.TotalCostContainer>
                <RoadworkLinearLengthUnique title={t(I18N_NAMESPACE, "containers.roadworksList.linearConcerned")}/>
            </Styled.TextWrapper>
            {!readOnly && openDrawModal && <Styled.IconButton id="create-roadwork-button" onClick={openDrawModal}>
                <AddIcon color="objects/object-invert" />
            </Styled.IconButton>}
        </Styled.Header>
    }, [
        openDrawModal,
        readOnly,
        t,
        currency,
        doneStatusCost,
        roadworks,
    ])

    if (!isLoading && isError) {
        return <RoadworksList
            className={className}
            roadworksItems={
                defaultRoadworksArray.map(() => <TechniqueItemCardError />)
            }
        />
    }

    if (isLoading || !currency?.symbol) {
        return <RoadworksList
            className={className}
            roadworksItems={
                defaultRoadworksArray.map(() => <TechniqueItemCardSkeleton />)
            }
        />
    }

    if (!displayedRoadworks) {
        return <>
            <Header />
            <Styled.Typography id="no-data-message">
                {t(I18N_NAMESPACE, "containers.roadRoadworksList.noData")}
            </Styled.Typography>
        </>
    }

    return (
        <>
            <Header />
            <RoadworksList
                addRoadwork={!readOnly && openDrawModal}
                className={className}
                roadworksItems={
                    displayedRoadworks.map((roadwork, index) => {
                        return <RoadworkCard
                            currency={currency}
                            index={index}
                            roadwork={roadwork} />
                    })
                }
            >
            </RoadworksList>
        </>
    )
}

RoadRoadworksList.propTypes = {
    className: PropTypes.string,
}

function sortRoadworks(roadworks) {
    return roadworks.sort((a, b) => {
        if (a.status !== b.status) {
            if (a.status === STATUS_DONE) {
                return 1
            } else {
                return -1
            }
        }

        if (a.year !== b.year) {
            return a.year - b.year
        }

        if (a.technique.impact !== b.technique.impact) {
            return impactsOrder[b.technique.impact] - impactsOrder[a.technique.impact]
        }
        return b.technique.cost - a.technique.cost
    })
}

function forceSortRoadworks(roadworks, sortedRoadworksUuids) {
    return roadworks.sort((a, b) => {
        const aUuidIndex = sortedRoadworksUuids.indexOf(a.uuid)
        const bUuidIndex = sortedRoadworksUuids.indexOf(b.uuid)
        if (aUuidIndex > bUuidIndex) {
            return 1
        } else {
            return -1
        }
    })
}

function areSameRoadworks(roadworks, roadworksUuids) {
    return roadworks?.length === roadworksUuids?.length && roadworks.every(roadwork => roadworksUuids.includes(roadwork.uuid))
}

const impactsOrder = {
    20: 1,
    40: 2,
    30: 3,
    10: 4,
} 