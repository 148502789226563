import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ColoredBadge } from "@l2r-front/l2r-ui"

import { GradeBadge } from "../../../../common/components/GradeBadge"
import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { mirandaLegend } from "../../constants/getNotationConfig"
import { useRoadwayStateContext } from "../../contexts/RoadwayContext"
import { useMirandaStats } from "../../hooks/queries/miranda/useMirandaStats"

import * as Styled from "./RoadMirandaGrade.styled"

export function RoadMirandaGrade() {

    const { selectedNetworkYearlyStatus } = useRoadwayStateContext()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { road } = useParams()

    const { data, isLoading, isError } = useMirandaStats({ linear_location_road: road })

    const mirandaGrade = useMemo(() => {
        if (data?.grade?.[selectedNetworkYearlyStatus?.year]) {
            return parseFloat(data.grade[selectedNetworkYearlyStatus.year])
        }
        return null
    }, [data, selectedNetworkYearlyStatus])

    if (isLoading || (!data && !isError)) {
        return <Styled.Skeleton />
    }

    if (isError) {
        return <ColoredBadge
            backgroundColor="surfaces/surface-error"
            text={t(I18N_NAMESPACE, "containers.weightedAverageNotationGrade.error")}
            textVariant="H3"
        />
    }

    return <GradeBadge grade={mirandaGrade} legend={mirandaLegend} />
}