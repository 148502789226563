import Popover from "@mui/material/Popover"

import * as Styled from "./L2RPopover.styled"

export const L2RPopover = (props) => {
    const {
        children,
        ...popoverProps
    } = props

    return <Styled.L2RPopover
        {...popoverProps}
    >
        {children}
    </Styled.L2RPopover>
}

L2RPopover.propTypes = Popover.propTypes