/* eslint-disable no-unused-vars */
import { LINEAR_ABSOLUTE_END_URL_PARAM, LINEAR_ABSOLUTE_START_URL_PARAM } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../constants/appRoad"
import { MODULE_HORIZONTAL_SIGNING, MODULE_ROADWAY, MODULE_ROADWORKS } from "../constants/modules"

export const getModulesBreadcrumb = (searchUrlParams = {}, _) => {

    return [
        {
            path: `/:project/${APP_ROAD}/${MODULE_ROADWAY}/:road`,
            newPath: `${MODULE_ROADWAY}?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_ROAD}/${MODULE_ROADWORKS}/:road/:roadworkUuid`,
            newPath: `${MODULE_ROADWORKS}/:road`,
        },
        {
            path: `/:project/${APP_ROAD}/${MODULE_ROADWORKS}/:road`,
            newPath: `${MODULE_ROADWORKS}?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/:road`,
            newPath: `${MODULE_HORIZONTAL_SIGNING}?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/code/*`,
            newPath: `${MODULE_HORIZONTAL_SIGNING}?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/:road/:signId`,
            newPath: `${MODULE_HORIZONTAL_SIGNING}/:road?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/:road/code/*`,
            newPath: `${MODULE_HORIZONTAL_SIGNING}/:road/?${searchUrlParams.toString()}`,
        },
        {
            path: `/:project/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/:road/code/:codeId/:signId`,
            newPath: `${MODULE_HORIZONTAL_SIGNING}/:road/code/:codeId?${searchUrlParams.toString()}`,
        },
    ]
}