import { styled, IconButton, L2RToggleButtonGroup } from "@l2r-front/l2r-ui"

export const Container = styled("div")(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(3),
    left: theme.spacing(3),
    width: "fit-content",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(3),

    "& > button, & > * > button": {
        width: "auto",
        height: "auto",
        display: "inline-flex",
        padding: "5px 6px",
        border: `1px solid ${theme.palette["border/border-primary"].main}80`,

        "&.Mui-selected:hover": {
            backgroundColor: theme.palette["cta-bg/cta-bg-primary"].dark,
        },
    },
}))

export const ModesToggle = styled(L2RToggleButtonGroup)(() => ({

    "&.MuiToggleButtonGroup-root": {
        outline: 0,
    },
}))

export const RestoreButton = styled(IconButton)(() => ({
    border: "0 !important",
    padding: "0 !important",
}))