import { APPBAR_WITHOUT_MODULES_HEIGHT, styled } from "@l2r-front/l2r-ui"

import { MobileAppBar as MobileAppBarComponent } from "../../components/MobileAppBar"
import { MobileBottomAppBar as L2rMobileBottomAppBar } from "../../components/MobileBottomAppBar"
import { BOTTOM_APPBAR_HEIGHT } from "../../constants/layout"

export const MobileAppBar = styled(MobileAppBarComponent)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
}))

export const ChildrenWrapper = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    height: "100%",
    paddingTop: APPBAR_WITHOUT_MODULES_HEIGHT,
    position: "relative",
    width: "100%",

    [`@media(max-width: ${theme.breakpoints.values.md}px)`]: {
        height: `calc(100% - (${BOTTOM_APPBAR_HEIGHT}px))`,
        width: "100%",
    },
}))

export const MobileBottomAppBar = styled(L2rMobileBottomAppBar)({
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
})