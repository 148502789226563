import { default as MuiTabs } from "@mui/material/Tabs"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./Tabs.styled"

export const Tabs = (props) => {
    const {
        className,
        tabsAttributes,
        tabElement,
        handleChangeTab,
        value,
        ...tabsProps
    } = props

    const TabElement = tabElement || Styled.Tab

    return <Styled.Tabs
        className={className}
        {...tabsProps}
        onChange={handleChangeTab}
        value={value}
    >
        {
            tabsAttributes.map(
                (tabAttributes, index) => {
                    return (
                        <TabElement
                            component={tabAttributes.path && "a"}
                            disabled={!tabAttributes.active}
                            href={tabAttributes.path}
                            icon={tabAttributes.icon}
                            key={`${tabAttributes.label}-${index}`}
                            iconPosition={tabAttributes.iconPosition || "top"}
                            id={tabAttributes.id}
                            label={tabAttributes.label}
                            onClick={e => e.preventDefault()}
                            value={tabAttributes.path || tabAttributes.value}
                            {...tabAttributes.props}
                        />
                    )
                })
        },
    </Styled.Tabs>
}

Tabs.propTypes = {
    ...MuiTabs.propTypes,
    handleChangeTab: PropTypes.func.isRequired,
    urlParam: PropTypes.string,
    tabsAttributes: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.element,
        iconPosition: PropTypes.string,
        path: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        props: PropTypes.object,
    }).isRequired),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
}
