import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { mirandaQueryKeys } from "./queryKeys"
import { useMirandaQuery } from "./useMirandaQuery"

export function useMirandaGradingLayer(year, queryParameters, config = {}) {

    const { selectedNetwork } = useNetworksStateContext()

    const parameters = {
        ...queryParameters,
        datetime__year: year,
        as_geojson: true,
        project: selectedNetwork?.slug,
    }
    const { enabled = true } = config
    const queryKey = mirandaQueryKeys["layer"]({ year, ...parameters })
    const url = "network_grades/"

    return useMirandaQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            enabled: !!year && enabled,
        },
    )
}
