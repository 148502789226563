import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"

import * as Styled from "./RoadworksStats.styled"

export function RoadworksStats(props) {
    const {
        title,
        children,
    } = props

    return (
        <Styled.StatsContainer>
            <Typography variant="H3">{title}</Typography>
            {children}
        </Styled.StatsContainer>
    )
}

RoadworksStats.propTypes = {
    title: PropTypes.string,
}