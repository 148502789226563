import {
    ListItemButton as UiListItemButton,
    ListItemSkeleton,
    Tooltip as UiTooltip,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

export const ListItemButton = styled(UiListItemButton, {
    shouldForwardProp: (prop) => prop !== "isDisabled",
})(({ isDisabled, selected, theme }) => ({
    "&:hover": {
        backgroundColor: isDisabled && `${theme.palette["surfaces/surface-disabled"].main} !important`,
    },
    "&:active": {
        backgroundColor: isDisabled && `${theme.palette["surfaces/surface-disabled"].main} !important`,
    },
    backgroundColor: isDisabled ? `${theme.palette["surfaces/surface-disabled"].main} !important`
        : `${theme.palette["surfaces/surface-primary"].main} !important`,
    border: `2px solid ${selected ? theme.palette["cta-bg/cta-bg-primary"].main : theme.palette["surfaces/surface-primary"].main}`,
    borderRadius: 12,
    cursor: isDisabled && "default",
    display: "flex",
    fontSize: selected ? theme.typography["H3"].fontSize : theme.typography["Regular"].fontSize,
    fontWeight: selected ? theme.typography["H3"].fontWeight : theme.typography["Regular"].fontWeight,
    justifyContent: "space-between",
    height: "fit-content",
    marginBottom: theme.spacing(3),
    opacity: isDisabled && "1 !important",
    padding: theme.spacing(1, 4),
    width: "100%",
}))

export const Label = styled(Typography)(({ selected, theme }) => ({
    marginLeft: "8%",
    marginRight: "auto",
    fontSize: selected ? theme.typography["H3"].fontSize : theme.typography["Regular"].fontSize,
    fontWeight: selected ? theme.typography["H3"].fontWeight : theme.typography["Regular"].fontWeight,
    textTransform: "capitalize !important",
}))

export const Tooltip = styled(UiTooltip)(() => ({
    marginLeft: "auto",
    marginRight: "6%",
}))

export const RoadInfoItemSkeleton = styled(ListItemSkeleton)(({ theme }) => ({
    borderRadius: 8,
    height: 46,
    marginBottom: theme.spacing(5),
    width: "100%",
}))