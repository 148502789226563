import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { l2rApps } from "../constants/l2rApps"

export const useGetActiveModules = () => {
    const { selectedNetwork } = useNetworksStateContext()

    if (!selectedNetwork) {
        return {}
    }

    const sanitizedApps = Object.keys(l2rApps).reduce((updatedApps, appKey) => {
        const app = Object.keys(l2rApps[appKey]).reduce((updatedApp, fieldKey) => {
            if (fieldKey !== "modules") {
                updatedApp[fieldKey] = l2rApps[appKey][fieldKey]
            } else {
                updatedApp[fieldKey] = Object.keys(l2rApps[appKey].modules).reduce((updatedModules, moduleKey) => {
                    const currentModuleSettings = l2rApps[appKey].modules[moduleKey]
                    if (currentModuleSettings.mandatoryModules) {
                        if (currentModuleSettings.mandatoryModules.every(mandatoryModule => selectedNetwork.modules.includes(mandatoryModule))) {
                            updatedModules[moduleKey] = l2rApps[appKey].modules[moduleKey]
                        }
                        return updatedModules
                    }
                    if (currentModuleSettings.alwaysEnabled
                        || currentModuleSettings.enablersModules?.some(enablerModule => selectedNetwork.modules.includes(enablerModule))
                        || selectedNetwork.modules.includes(l2rApps[appKey].modules[moduleKey].id)) {
                        updatedModules[moduleKey] = l2rApps[appKey].modules[moduleKey]
                    }

                    return updatedModules
                }, {})
            }

            return updatedApp
        }, {})

        if (Object.keys(app.modules).length !== 0) {
            updatedApps[appKey] = app
        }

        return updatedApps
    }, {})

    return sanitizedApps
}