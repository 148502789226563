import { useCallback } from "react"

import { useMatch, useNavigate } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./AccountSettingsMenuItem.styled"

export const AccountSettingsMenuItem = (props) => {
    const {
        icon,
        label,
        to,
    } = props

    const navigate = useNavigate()
    const match = useMatch("/account_settings/:currentSetting")
    const selected = match?.params.currentSetting === to || (!match && to === "")

    const Icon = icon

    const handleClick = useCallback(() => {
        navigate(to, {replace: true})
    }, [to, navigate])

    return (
        <Styled.Container onClick={handleClick}>
            <Styled.Content>
                <Styled.Icon>
                    <Icon color="cta-bg/cta-bg-primary" />
                </Styled.Icon>
                <Styled.Label>{label}</Styled.Label>
            </Styled.Content>
            {selected && <Styled.SelectedMarker />}
        </Styled.Container>
    )
}

AccountSettingsMenuItem.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]).isRequired,
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
}