import {
    styled,
    Drawer as UiDrawer,
} from "@mui/material"

import { SIDEBAR_WIDTH } from "../../constants/layout"

export const Drawer = styled(UiDrawer, { shouldForwardProp: prop => prop !== "shadowed" })(({ theme, shadowed }) => ({
    boxShadow: shadowed ? "0px 0px 20px 0px rgba(0, 0, 0, 0.20)" : "",
    position: "relative",
    height: "100%",
    width: SIDEBAR_WIDTH,
    zIndex: theme.zIndex.drawer + 1,
    "& .MuiDrawer-paper": {
        position: "absolute",
        height: "100%",
        width: "100%",
        borderWidth: 0,
        padding: theme.spacing(5, 0, 0, 0),
    },
}))